// shared/hooks/usePagination.ts
import { ChangeEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface UsePaginationProps {
  currentPage: number
  totalPages: number
  perPage: number
  totalItems: number
}

export function usePagination({
  currentPage,
  totalPages,
  perPage,
  totalItems,
}: UsePaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [inputValue, setInputValue] = useState('')
  const MIN_WIDTH = 41 // in pixels
  const CHAR_WIDTH = 10 // approximate width per character in pixels (adjust as needed)
  const [inputWidth, setInputWidth] = useState(MIN_WIDTH)
  const handlePageChange = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    })
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // Only allow digits:
    if (/^\d*$/.test(value)) {
      setInputValue(value)
    }

    setInputWidth(
      Math.max(MIN_WIDTH, value.length * CHAR_WIDTH + MIN_WIDTH)
    )
  }

  const handleInputSubmit = () => {
    if (inputValue) {
      const page = Math.max(
        1,
        Math.min(Number(inputValue), totalPages)
      )
      handlePageChange(page)
    }
  }

  const getPageNumbers = () => {
    let startPage = Math.max(1, currentPage - 2)
    const endPage = Math.min(startPage + 4, totalPages)

    if (endPage - startPage < 3) {
      startPage = Math.max(1, endPage - 3)
    }

    const pages: number[] = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }
    return pages
  }
  const startItem = (currentPage - 1) * perPage + 1
  const endItem = Math.min(currentPage * perPage, totalItems)

  return {
    inputValue,
    handleInputChange,
    handleInputSubmit,
    handlePageChange,
    getPageNumbers,
    inputWidth,
    // Optional helpers:
    startItem,
    endItem,
  }
}
