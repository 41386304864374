import { useTypedSelector } from '@/shared/store'

import { useRawHtmlRenderer } from '../../modal/useRawHtmlRenderer'

export const RawHtmlRenderer = ({ rawHtml }: { rawHtml: string }) => {
  const { rawRef, processedHtml } = useRawHtmlRenderer(rawHtml)

  return (
    <div
      ref={rawRef}
      className="documentContent documentPreview publicate w-full"
      dangerouslySetInnerHTML={{ __html: processedHtml }}
    />
  )
}
