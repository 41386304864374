/** Utility: shallow comparison of plain objects */
export function shallowEqual(
  objA: Record<string, unknown>,
  objB: Record<string, unknown>
) {
  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)
  if (keysA.length !== keysB.length) return false

  for (const key of keysA) {
    if (objA[key] !== objB[key]) return false
  }
  return true
}

/** Utility: remove given keys from an object (non-mutating) */
export function omitKeys<T extends Record<string, unknown>>(
  obj: T,
  keysToOmit: string[]
): Partial<T> {
  const newObj = { ...obj }
  for (const key of keysToOmit) {
    delete newObj[key as keyof T]
  }
  return newObj
}
