import { FavoriteChatMessages, IChatMessage } from '@/entities/chat'
import { cn } from '@/shared/utils/common'
import { useEffect } from 'react'

import { useSidebar } from '../../Sidebar'

interface Props {
  messages: IChatMessage[]
}

export const FavoriteChatContainer = ({ messages }: Props) => {
  const { isSidebarOpen } = useSidebar()

  useEffect(() => {
    const lastMessageElement = document.getElementById('last-message')
    if (lastMessageElement) {
      lastMessageElement.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  if (!messages) return null

  return (
    <div className="relative flex min-h-full w-full flex-col">
      <div className="flex-grow overflow-y-auto">
        <div
          className={cn(
            isSidebarOpen ? 'lg:min-w-[672px]' : 'lg:min-w-[768px]',
            `relative mx-auto flex w-full min-w-[672px] flex-col items-stretch
            justify-start gap-8 self-center bg-[#FCFCFC] pb-16 pt-24
            lg:max-w-[850px]`
          )}
        >
          <FavoriteChatMessages messages={messages} />
        </div>
      </div>
    </div>
  )
}
