import IconArrowRightDark from '@/shared/assets/icons/icon_arrow_right_dark.svg?react'
import IconChevronDuoLeft from '@/shared/assets/icons/icon_chevron_duo_left.svg?react'
import IconChevronDuoRight from '@/shared/assets/icons/icon_chevron_duo_right.svg?react'
import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left.svg?react'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import { usePagination } from '@/shared/hooks/usePagination'
import {
  Button,
  IconButton,
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { Separator } from '@radix-ui/react-separator'

interface CustomPaginationProps {
  currentPage: number
  totalPages: number
  perPage: number
  totalItems: number
}

export const CustomPagination = ({
  currentPage,
  totalPages,
  perPage,
  totalItems,
}: CustomPaginationProps) => {
  const {
    getPageNumbers,
    handleInputChange,
    handleInputSubmit,
    handlePageChange,
    inputValue,
    inputWidth,
  } = usePagination({
    currentPage: currentPage,
    totalItems: totalItems,
    totalPages: totalPages,
    perPage: perPage,
  })

  return (
    <div
      className="my-8 inline-flex w-full min-w-0 flex-col items-stretch justify-start
        gap-4 self-center"
    >
      <div className="flex flex-wrap items-start justify-center gap-4">
        <div className="flex flex-col gap-2">
          <Pagination className="shrink grow-0 bg-white">
            <PaginationContent>
              {/* Go first page */}
              <PaginationItem className="group">
                <IconButton
                  className={cn(
                    `flex max-h-9 items-center justify-center border
                    border-stroke-light-gray-2 [&_path]:stroke-[#25272C]`,
                    'bg-bg-light-white-1 p-2 group-hover:bg-base-100',
                    currentPage === 1 &&
                      'cursor-not-allowed [&_path]:stroke-[#8D95A3]'
                  )}
                  onClick={(e) => {
                    if (currentPage > 1) {
                      e.preventDefault()
                      handlePageChange(1)
                    }
                  }}
                >
                  <IconChevronDuoLeft className="group-hover:bg-base-100" />
                </IconButton>
              </PaginationItem>
              {/* Go to the previous page */}
              <PaginationItem className="group">
                <IconButton
                  className={cn(
                    `flex max-h-9 items-center justify-center border
                    border-stroke-light-gray-2`,
                    'bg-bg-light-white-1 p-2 group-hover:bg-base-100',
                    currentPage === 1 &&
                      'cursor-not-allowed [&_path]:stroke-[#8D95A3]'
                  )}
                  onClick={(e) => {
                    if (currentPage > 1) {
                      e.preventDefault()
                      handlePageChange(currentPage - 1)
                    }
                  }}
                >
                  <IconChevronLeft className="group-hover:bg-base-100" />
                </IconButton>
              </PaginationItem>
              {getPageNumbers().map((page) => (
                <PaginationItem key={page}>
                  <PaginationLink
                    className="flex max-h-9 w-full min-w-9 items-center justify-center !px-4 !py-2
                      !leading-5"
                    isActive={page === currentPage}
                    disabled={page === currentPage}
                    onClick={(e) => {
                      if (page === currentPage) return
                      e.preventDefault()
                      handlePageChange(page)
                    }}
                  >
                    <Typography
                      variant={'label'}
                      className={cn(
                        'font-normal',
                        currentPage === page
                          ? 'font-medium text-white'
                          : 'text-base-950'
                      )}
                    >
                      {page}
                    </Typography>
                  </PaginationLink>
                </PaginationItem>
              ))}
              {/* Go to the next page */}
              <PaginationItem className="group">
                <IconButton
                  className={cn(
                    `flex max-h-9 items-center justify-center border
                    border-stroke-light-gray-2`,
                    'bg-bg-light-white-1 p-2 group-hover:bg-base-100',
                    currentPage === totalPages &&
                      'cursor-not-allowed [&_path]:stroke-[#8D95A3]'
                  )}
                  onClick={(e) => {
                    if (currentPage < totalPages) {
                      e.preventDefault()
                      handlePageChange(currentPage + 1)
                    }
                  }}
                >
                  <IconChevronRight className="group-hover:bg-base-100" />
                </IconButton>
              </PaginationItem>
              {/* Go to LAST page */}
              <PaginationItem className="group">
                <IconButton
                  className={cn(
                    `flex max-h-9 items-center justify-center border
                    border-stroke-light-gray-2`,
                    'bg-bg-light-white-1 p-2 group-hover:bg-base-100',
                    currentPage === totalPages &&
                      'cursor-not-allowed [&_path]:stroke-[#8D95A3]'
                  )}
                  onClick={(e) => {
                    if (currentPage < totalPages) {
                      e.preventDefault()
                      handlePageChange(totalPages)
                    }
                  }}
                >
                  <IconChevronDuoRight className="group-hover:bg-base-100" />
                </IconButton>
              </PaginationItem>
            </PaginationContent>
          </Pagination>
          <div className="flex flex-col items-stretch justify-start gap-2">
            <Typography
              variant={'label'}
              className="font-medium text-text-light-black-3"
            >
              {currentPage * perPage - perPage + 1}-
              {Math.min(currentPage * perPage, totalItems)} из{' '}
              {totalItems}
            </Typography>
          </div>
        </div>
        <div className="h-[36px] w-[1px] bg-stroke-light-gray-2" />
        <div className="flex shrink grow-0 items-center justify-start gap-3">
          <Typography variant={'body'}>Ввести страницу</Typography>
          <input
            maxLength={4}
            value={inputValue}
            onChange={handleInputChange}
            style={{ width: `${inputWidth}px` }}
            className="h-[36px] items-center overflow-visible rounded-lg border
              border-base-200 px-4 py-2 text-center focus:outline-none focus:ring-2
              focus:ring-stroke-light-blue-1"
          />

          {inputValue !== '' && (
            <Button
              variant={'outlined'}
              className="border border-stroke-light-gray-2 bg-bg-light-white-1 py-2 pl-4 pr-6"
              style={{
                boxShadow:
                  '0px 18px 5px 0px rgba(0, 0, 0, 0.00), 0px 12px 5px 0px rgba(0, 0, 0, 0.00), 0px 7px 4px 0px rgba(0, 0, 0, 0.02), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
              }}
              leftIcon={<IconArrowRightDark />}
              onClick={handleInputSubmit}
            >
              Перейти
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
