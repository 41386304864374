import { documentStatusBadgeVariants } from '@/entities/document/consts'
import {
  DocumentLanguages,
  EDocumentStatus,
} from '@/entities/document/types'
import { DocumentPanelSkeleton } from '@/entities/panel/ui/DocumentPanelSkeleton'
import { RawHtmlMainRenderer } from '@/entities/panel/ui/RawHtmlMainRenderer'
import { useGetDocumentByIdQuery } from '@/shared/api/documents'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { Typography } from '@/shared/ui'
import { useParams, useSearchParams } from 'react-router-dom'

import { WidgetDocumentMenuActions } from '../WidgetDocumentMenuActions'

export const WidgetDocumentContents = () => {
  const { id: documentId } = useParams()
  const [searchParams] = useSearchParams()
  const languageFromUrl = searchParams.get('language')
  const language: DocumentLanguages =
    (languageFromUrl as DocumentLanguages) || 'ru'
  const isMobile = useMediaQuery('(max-width: 960px)')
  const { data, error, isLoading, isFetching } =
    useGetDocumentByIdQuery({
      id: Number(documentId),
      language,
    })

  if (isLoading || isFetching) {
    return (
      <div
        className="relative mx-auto flex h-[100vh] w-full max-w-[1130px] flex-col
          items-stretch justify-start pb-8"
      >
        <DocumentPanelSkeleton />
      </div>
    )
  }

  if (error) {
    return <Typography>Error...</Typography>
  }

  return (
    <div
      className="relative mx-auto flex h-[calc(100svh-96px)] max-w-[1480px]
        items-stretch justify-start gap-6 pt-3 xs:px-0 md:px-0"
    >
      {/* TO DO: Add go back button here later so container will be at center */}
      <div className="flex w-full flex-col items-stretch justify-start gap-8 bg-white">
        {data && data.document.content ? (
          <div
            className="relative rounded-[16px] border border-base-100 bg-white pb-[88px] pt-0
              shadow-shadow-modal-light"
            id="document-id-title"
          >
            <div className="px-[40px] xs:px-5 xs:pt-8">
              {/* Document Status and requisite*/}
              <div className="title cls-container mb-5">
                <div className="text cls-text mb-2">
                  Статус документа:{' '}
                  {
                    documentStatusBadgeVariants[
                      data?.document?.status.code as EDocumentStatus
                    ].label
                  }
                </div>
                <div className="text cls-text">
                  {data?.document?.requisites ?? null}
                </div>
              </div>
              {/* Document contents */}
              <RawHtmlMainRenderer
                rawHtml={data?.document.content}
                index={0}
              />
            </div>
          </div>
        ) : (
          <DocumentPanelSkeleton />
        )}
      </div>
      {data && data.document.content && !isMobile && (
        <WidgetDocumentMenuActions
          languages={data.document.locale.available}
          documentId={documentId}
          versions={data.document.versions}
        />
      )}
    </div>
  )
}
