import { IDocumentInfo } from '@/entities/panel/types'
import { Typography } from '@/shared/ui/Typography'
import React from 'react'

interface InfoSectionProps {
  label: string
  children: React.ReactNode
}

const InfoSection: React.FC<InfoSectionProps> = ({
  label,
  children,
}) => (
  <div
    className="flex flex-wrap items-center gap-2 border-b
      border-b-stroke-light-gray-1 py-2"
  >
    <Typography
      variant="body"
      className="basis-[152px] font-semibold"
    >
      {label}
    </Typography>
    <div className="flex flex-wrap items-center gap-2">
      {children}
    </div>
  </div>
)

interface DocumentInfoContentProps {
  documentInfo: IDocumentInfo
}

export const DocumentInfoContent: React.FC<
  DocumentInfoContentProps
> = ({ documentInfo }) => {
  return (
    <div className="flex flex-col items-stretch justify-start gap-4 overflow-x-auto">
      {/* Publication Date */}
      <InfoSection label="Дата публикации:">
        <Typography
          variant="label"
          className="flex-1 whitespace-nowrap rounded-[4px] px-2 py-[2px]"
        >
          {documentInfo.published_at || 'Не указана'}
        </Typography>
      </InfoSection>

      {/* Document Types */}
      <InfoSection label="Тип документа:">
        {documentInfo.types && documentInfo.types.length > 0 ? (
          documentInfo.types.map((item, index) => (
            <Typography
              key={index}
              variant="label"
              className="whitespace-nowrap rounded-[4px] bg-stroke-light-gray-1 px-2 py-[2px]
                font-semibold"
            >
              {item.name || ''}
            </Typography>
          ))
        ) : (
          <Typography variant="label" className="whitespace-nowrap">
            Не указано
          </Typography>
        )}
      </InfoSection>

      {/* Legal Field */}
      <InfoSection label="Отрасль права:">
        {documentInfo.groups && documentInfo.groups.length > 0 ? (
          documentInfo.groups.map((group, index) => (
            <Typography
              key={index}
              variant="label"
              className="whitespace-nowrap rounded-[4px] bg-stroke-light-gray-1 px-2 py-[2px]
                font-semibold"
            >
              {group.name || ''}
            </Typography>
          ))
        ) : (
          <Typography variant="label" className="whitespace-nowrap">
            Не указано
          </Typography>
        )}
      </InfoSection>

      {/* Developing Organizations */}
      <InfoSection label="Орган разработки:">
        {documentInfo.developingOrganizations &&
        documentInfo.developingOrganizations.length > 0 ? (
          documentInfo.developingOrganizations.map((org, index) => (
            <Typography
              key={index}
              variant="label"
              className="whitespace-nowrap rounded-[4px] bg-stroke-light-gray-1 px-2 py-[2px]
                font-semibold"
            >
              {org.name || ''}
            </Typography>
          ))
        ) : (
          <Typography variant="label" className="whitespace-nowrap">
            Не указано
          </Typography>
        )}
      </InfoSection>

      {/* Approving Organizations */}
      <InfoSection label="Орган принятия:">
        {documentInfo.approvingOrganizations &&
        documentInfo.approvingOrganizations.length > 0 ? (
          documentInfo.approvingOrganizations.map((org, index) => (
            <Typography
              key={index}
              variant="label"
              className="whitespace-nowrap rounded-[4px] bg-stroke-light-gray-1 px-2 py-[2px]
                font-semibold"
            >
              {org.name || ''}
            </Typography>
          ))
        ) : (
          <Typography variant="label" className="whitespace-nowrap">
            Не указано
          </Typography>
        )}
      </InfoSection>
    </div>
  )
}
