import { RadioGroup, RadioGroupItem } from '@/shared/ui/RadioButton'
import { Typography } from '@/shared/ui/Typography'

import { ISortMode } from '../../types'

type Props = {
  handleSortModeChange: (sortMode: ISortMode) => void
  items: Partial<Record<ISortMode, string>>
  selectedSortMode: string
}

export const SortModeFilterItemDesktop = ({
  handleSortModeChange,
  items,
  selectedSortMode,
}: Props) => {
  return (
    <>
      <RadioGroup
        value={selectedSortMode}
        onValueChange={handleSortModeChange}
        className="gap-1"
      >
        {Object.entries(items).map(([value, label]) => (
          <label
            key={value}
            className="flex items-center justify-start gap-3 p-2"
          >
            <RadioGroupItem value={value as ISortMode} />
            <Typography
              variant="body"
              className="text-sm font-normal"
            >
              {label}
            </Typography>
          </label>
        ))}
      </RadioGroup>
    </>
  )
}
