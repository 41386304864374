import { DialogDocumentVersionSelect } from '@/entities/document'
import { DocumentData } from '@/entities/document/types'
import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left_dark.svg?react'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import { IconButton, Typography } from '@/shared/ui'

interface DocumentVersionSwitchDiffProps {
  current: number
  total: number
  type?: 'deleted' | 'added'
  onClick: (type: 'prev' | 'next') => void
}

export const DocumentVersionSwitchDiffMobile = ({
  current,
  total,
  type,
  onClick,
}: DocumentVersionSwitchDiffProps) => {
  return (
    <div className="flex w-full justify-between gap-3">
      <IconButton
        className="h-10 w-10 rounded-xl border-[1px] border-gray-300 p-3"
        onClick={() => onClick('prev')}
      >
        <IconChevronLeft width={'14px'} height={'14px'} />
      </IconButton>
      <div className="flex items-center gap-2">
        <Typography className="content-center text-sm">
          Изменение: {current}/{total}
        </Typography>
        {type && (
          <Typography
            className={`h-5 content-center rounded px-2 py-[2px] text-xs font-semibold
            ${type === 'added' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}
          >
            {type === 'added' ? 'Добавленное' : 'Удаленное'}
          </Typography>
        )}
      </div>

      <IconButton
        className="h-10 w-10 rounded-xl border-[1px] border-gray-300 p-3"
        onClick={() => onClick('next')}
      >
        <IconChevronRight width={'14px'} height={'14px'} />
      </IconButton>
    </div>
  )
}
