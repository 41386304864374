import { ISearchMode } from '@/entities/document'
import IconAiFilled from '@/shared/assets/icons/icon_ai_filled.svg?react'
import IconDocumentMagnify from '@/shared/assets/icons/icon_document_magnify.svg?react'
import IconLetterT from '@/shared/assets/icons/icon_letter_T.svg?react'
import { Typography } from '@/shared/ui'
import { ReactNode } from 'react'

type SearhModeData = {
  name: string
  icon: ReactNode
  badge?: ReactNode
  placeholder?: string
}

export const searchModeConfig: Record<ISearchMode, SearhModeData> = {
  IN_TITLE: {
    name: 'Поиск по названию',
    icon: <IconLetterT className="max-h-[18px]" />,
    placeholder: 'Поиск по названию документа...',
  },
  CONTEXT: {
    name: 'Поиск по контексту',
    icon: <IconDocumentMagnify className="max-h-[18px]" />,
    placeholder: 'Поиск по контексту документа...',
  },
  CONTEXT_PLUS: {
    name: 'AI-поиск',
    icon: (
      <IconAiFilled className="max-h-[18px] [&_path]:fill-additional-blue-2" />
    ),
    placeholder: 'AI-поиск по контексту документа...',
    badge: (
      <Typography
        variant={'subtitle'}
        className="ml-2 rounded-[4px] border border-additional-blue-2 bg-[#E7EFFE] px-2
          py-[2px] font-semibold text-additional-blue-2"
      >
        Plus
      </Typography>
    ),
  },
}
