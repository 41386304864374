import { ISearchMode } from '@/entities/document'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_ai_right.svg?react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/ui/DropdownMenu'
import { RadioGroup, RadioGroupItem } from '@/shared/ui/RadioButton'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'

import { searchModeConfig } from '../../consts'
import { useSearchModeFilter } from '../../hooks/useSearchModeFilter'

export const SearchModeFilterMenuDesktop = () => {
  const {
    handleApply,
    handleReset,
    isDropDownOpen,
    handleSearchModeChange,
    selectedSearchMode,
    handleToggleDropDown,
  } = useSearchModeFilter('IN_TITLE')
  // TODO: Добавить новый конфиг на мобилку
  return (
    <DropdownMenu
      open={isDropDownOpen}
      onOpenChange={handleToggleDropDown}
    >
      <DropdownMenuTrigger
        className={cn(
          'flex h-[32px] w-[222px] items-center justify-start gap-[6px]',
          `self-stretch rounded-[16px] shadow-button transition-colors
          duration-300 ease-in-out focus-visible:outline-0`,
          isDropDownOpen
            ? 'border-[1.5px] border-additional-light-blue-2'
            : 'border border-stroke-light-gray-2',
          'bg-[#FBFBFB] p-2'
        )}
      >
        <span className="flex flex-1 items-center justify-start gap-1">
          {searchModeConfig[selectedSearchMode].icon}
          <Typography variant={'label'} className="font-medium">
            {searchModeConfig[selectedSearchMode].name}
          </Typography>
          {searchModeConfig[selectedSearchMode].badge &&
            searchModeConfig[selectedSearchMode].badge}
        </span>
        <IconChevronRight
          className={cn(
            'h-4 w-4 transition-transform duration-300 [&_path]:stroke-[1.5]',
            isDropDownOpen ? 'rotate-90' : ''
          )}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="w-[222px] rounded-2xl bg-white p-2"
      >
        <DropdownMenuItem className="p-0">
          <RadioGroup
            value={selectedSearchMode}
            onValueChange={handleSearchModeChange}
          >
            {Object.entries(searchModeConfig).map(([value, item]) => (
              <div
                className="rounded-lg px-2 py-1 hover:cursor-pointer hover:bg-[#F0F0F0]"
                key={value}
              >
                <label
                  onClick={() =>
                    handleApply({ searchMode: value as ISearchMode })
                  }
                  className="flex items-center justify-start gap-2 py-[6px]"
                >
                  {searchModeConfig[value as ISearchMode].icon ? (
                    <div>
                      {searchModeConfig[value as ISearchMode].icon}
                    </div>
                  ) : null}
                  <Typography
                    variant="body"
                    className="flex-1 whitespace-nowrap text-sm font-normal"
                  >
                    {item.name}
                    {searchModeConfig[value as ISearchMode].badge
                      ? searchModeConfig[value as ISearchMode].badge
                      : null}
                  </Typography>
                  <RadioGroupItem value={value as ISearchMode} />
                </label>
              </div>
            ))}
          </RadioGroup>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
