import { IChatHistoryItem } from '@/entities/chat/types'
import { useChatHistoryItem } from '@/entities/chatHistory'
import {
  ChatRenameInput,
  MenubarDeleteChat,
  MenubarToggleFavoriteChat,
  useChatRename,
} from '@/features/chat'
import IconMoreGray from '@/shared/assets/icons/icon_more_gray.svg?react'
import IconPenBlack from '@/shared/assets/icons/icon_pen_black.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { useBoundary } from '@/shared/utils/useBoundary'
import { useSidebar } from '@/widgets/Sidebar'
import { Arrow } from '@radix-ui/react-menubar'
import { useNavigate, useParams } from 'react-router-dom'

interface ChatHistoryItemProps {
  item: IChatHistoryItem
}

// TODO:Слишком тяжелый файл. Нужно будет вынести логику в хук, оптимизировать код и почистить ненужное
export const ChatHistoryItem = ({ item }: ChatHistoryItemProps) => {
  const { isHovered, triggerRef } = useChatHistoryItem()
  const { toggleSidebar } = useSidebar()
  const { chat_id } = useParams<{ chat_id: string }>()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 960px)')
  const boundaryElements = useBoundary('menu_boundary')
  const {
    isBeingRenamed,
    newTitle,
    setNewTitle,
    setIsBeingRenamed,
    inputRef,
    handleRenameChat,
  } = useChatRename(item.id, item.title)

  return (
    <Menubar
      className={cn(
        'flex h-[36px] items-center justify-stretch self-stretch border-none',
        'rounded-lg shadow-none hover:cursor-pointer hover:bg-base-100',
        item.id === chat_id ? 'bg-base-100' : ''
      )}
    >
      <MenubarMenu>
        <div
          className="relative flex w-full items-center justify-between self-stretch
            overflow-hidden p-2"
          ref={triggerRef}
          onClick={() => {
            isMobile && toggleSidebar()
            navigate(`/chats/${item.id}`)
          }}
        >
          {isBeingRenamed ? (
            <ChatRenameInput
              newTitle={newTitle}
              setNewTitle={setNewTitle}
              handleRenameChat={handleRenameChat}
              setIsBeingRenamed={setIsBeingRenamed}
              inputRef={inputRef}
            />
          ) : (
            <Typography
              variant={'label'}
              className="relative shrink grow-0 basis-full overflow-hidden whitespace-nowrap"
            >
              {item.title}
            </Typography>
          )}
          {!isBeingRenamed && (
            <MenubarTrigger onClick={(e) => e.stopPropagation()}>
              {isHovered || item.id === chat_id ? (
                <div
                  className={cn(
                    'absolute right-2 top-2 z-50 flex items-center justify-end gap-1',
                    'bg-fade-gradient py-[1px] pl-[22px] hover:cursor-pointer'
                  )}
                >
                  <IconMoreGray
                    height={18}
                    width={18}
                    className="[&_path]:fill-base-950"
                  />
                </div>
              ) : (
                <div
                  className="absolute right-2 top-2 flex h-[20px] w-[18px] items-center justify-end
                    gap-1 bg-fade-gradient-white py-[1px] pl-[22px]"
                />
              )}
            </MenubarTrigger>
          )}
        </div>
        <MenubarContent
          collisionBoundary={boundaryElements || undefined}
          className="z-[120] flex min-w-[169px] max-w-[169px] flex-col items-stretch
            justify-start rounded-[20px] border-none bg-base-20 p-2"
          align="center"
          sideOffset={12}
          style={{
            boxShadow:
              '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
          }}
          side="right"
        >
          <Arrow fill="#FCFDFD" className="h-2 w-5" />
          <MenubarItem
            className="flex items-center justify-start gap-2 rounded-lg p-2
              hover:cursor-pointer hover:bg-slate-100"
            inset={true}
            onClick={(e) => {
              e.stopPropagation()
              setNewTitle(item.title) // Reset title to item's original title
              setIsBeingRenamed(true)
            }}
          >
            <IconPenBlack className="h-5 w-5" />
            <Typography
              className="whitespace-nowrap font-normal"
              variant={'label'}
            >
              Переименовать
            </Typography>
          </MenubarItem>
          <MenubarToggleFavoriteChat item={item} />
          <MenubarDeleteChat itemId={item.id} />
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
