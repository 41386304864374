import { CustomFilterInput, ECategoryType } from '@/entities/filter'
import { Subtypes } from '@/entities/filter/types'
import { useSubtypesFilter } from '@/features/filters/FilterAccordion/hooks/useSubtypesFilter'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'

import { FilterAccordion } from '../FilterAccordion'

export const FilterMenuDesktop = ({
  categoryType,
  searchParam,
}: Subtypes) => {
  const {
    subtypes,
    isFiltered,
    allSelected,
    setSubtypes,
    setTotalSubtypesCount,
    selectedIndices,
    setIsFiltered,
    handleSelectAll,
    handleCheckboxChange,
  } = useSubtypesFilter({ categoryType, searchParam })

  return (
    <div className="relative flex flex-col overflow-hidden">
      <div
        className="flex flex-col items-stretch gap-2"
        onClick={(e) => e.preventDefault()}
      >
        <CustomFilterInput
          setIsFiltered={setIsFiltered}
          setTotalCount={setTotalSubtypesCount}
          setFilterItems={setSubtypes}
          categoryType={categoryType}
        />
        <div className="flex items-center justify-start gap-2 px-2 py-[10px]">
          <Checkbox
            variant={'secondary'}
            size={'small'}
            checked={allSelected}
            onCheckedChange={handleSelectAll}
            id="select-all-checkbox"
          />
          <Typography variant={'subtitle'} className="ml-1 text-sm">
            Все
          </Typography>
        </div>
      </div>
      <FilterAccordion
        parentId={-1}
        level={0}
        subtypes={subtypes}
        isFiltered={isFiltered}
        selectedIndices={selectedIndices}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  )
}
