import { useDocumentsSearchInput } from '@/features/filters'
import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'
import { Input, InputProps } from '@/shared/ui/Input'
import { ForwardedRef } from 'react'

interface Props extends InputProps {
  onValueChange?: (value: string) => void
  ref?: ForwardedRef<HTMLInputElement>
}

export const MobileDocumentsSearchInput = ({
  ref,
  onValueChange,
  ...props
}: Props) => {
  const { value, handleChange, handleKeyDown, handleSubmit } =
    useDocumentsSearchInput()

  return (
    <Input
      {...props}
      ref={ref}
      value={value}
      canRemove={false}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="Поиск по названию документа..."
      className="h-12 rounded-[100px] bg-bg-light-gray-2"
      rightIcon={
        <IconSearch
          className="h-5 w-5 cursor-pointer text-gray-600"
          onClick={handleSubmit}
        />
      }
    />
  )
}
