import { IChatAiFile } from '@/entities/chat/types'
import { usePostToggleFavoriteDocumentMutation } from '@/shared/api/documents'
import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import { MenubarItem, Typography } from '@/shared/ui'

interface MenubarToggleFavoriteDocumentProps {
  file: IChatAiFile
}
export const MenubarToggleFavoriteDocument = ({
  file,
}: MenubarToggleFavoriteDocumentProps) => {
  const [toggleFavoriteDocument] =
    usePostToggleFavoriteDocumentMutation({})

  return (
    <MenubarItem
      className="mb-1 flex items-center justify-start gap-2 rounded-lg p-2
        hover:cursor-pointer hover:bg-additional-light-blue"
      onClick={() => toggleFavoriteDocument({ document_id: file.id })}
      inset={true}
    >
      <IconBookmarkBlack className="h-5 w-5" />
      <Typography
        variant="label"
        className="whitespace-nowrap font-normal text-black"
      >
        В избранное
      </Typography>
    </MenubarItem>
  )
}
