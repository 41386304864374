import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_black.svg?react'
import IconUnmark from '@/shared/assets/icons/icon_unmark.svg?react'
import { IconButton, MenubarItem, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

import { useToogleFavouriteDocument } from '../../hooks/useToogleFavouriteDocument'

interface ActionDocumentBookmarkProps {
  documentId?: string
  isFavorite?: boolean
  withLabel?: boolean
}

export const ActionDocumentBookmark = ({
  documentId,
  isFavorite,
  withLabel = false,
}: ActionDocumentBookmarkProps) => {
  const {
    handleToggleFavorite,
    handleMouseEnter,
    handleMouseLeave,
    isBookmarkHovered,
    handleClick,
  } = useToogleFavouriteDocument(Number(documentId))

  if (!withLabel) {
    return (
      <IconButton
        className="h-8 w-8 p-2 hover:bg-base-100"
        onClick={handleToggleFavorite}
      >
        <IconBookmarkBlack
          className={cn(
            'h-6 w-6 transition-transform duration-300 ease-in-out',
            isFavorite
              ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
              : ''
          )}
        />
      </IconButton>
    )
  }
  return (
    <MenubarItem
      key={`${documentId} favorite`}
      className="flex items-center justify-start gap-2 rounded-lg p-2
        hover:cursor-pointer hover:bg-slate-100"
      inset={true}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        handleClick()
        e.stopPropagation()
        handleToggleFavorite()
      }}
    >
      {isFavorite && isBookmarkHovered ? (
        <IconUnmark className="h-5 w-5" />
      ) : (
        <IconBookmarkBlack
          className={cn(
            'h-5 w-5',
            isFavorite
              ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
              : ''
          )}
        />
      )}
      <Typography
        className="whitespace-nowrap font-normal"
        variant={'label'}
      >
        {isFavorite
          ? isBookmarkHovered
            ? 'Из избранных'
            : 'В избранных'
          : 'В избранное'}
      </Typography>
    </MenubarItem>
  )
}
