import IconCloseRound from '@/shared/assets/icons/icon_close_round.svg?react'
import IconSortDark from '@/shared/assets/icons/icon_sort_dark.svg?react'
import { DropdownMenuTrigger } from '@/shared/ui'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { forwardRef } from 'react'
import { useSearchParams } from 'react-router-dom'

interface CustomInputProps {
  onClick?: () => void
  className?: string
  onRemove?: () => void
}

export const SortModeFilterCustomInput = forwardRef<
  HTMLButtonElement,
  CustomInputProps
>(({ onRemove: handleRemove, onClick, className, ...props }, ref) => {
  const [searchParams] = useSearchParams()
  const sortModeSelected = searchParams.has('sort_mode')

  return (
    <div className="relative flex max-h-7">
      <DropdownMenuTrigger
        ref={ref}
        onClick={onClick}
        className={cn(
          'px-3 py-1',
          'bg-bg-light-white-1',
          'flex gap-3 rounded-lg border',
          sortModeSelected
            ? 'border-[#4D8EFF]'
            : 'border-stroke-light-gray-2',
          className
        )}
        {...props}
      >
        <Typography
          variant={'label'}
          className="whitespace-nowrap font-medium"
        >
          Сортировать
        </Typography>
        <IconSortDark className="h-4 w-4" />
      </DropdownMenuTrigger>
      {sortModeSelected && (
        <IconCloseRound
          className="absolute right-0 top-0 z-50 h-[14px] w-[14px] -translate-y-[7px]
            translate-x-[7px] transition-transform hover:scale-110
            hover:cursor-pointer"
          onClick={(e) => {
            e.stopPropagation()
            handleRemove?.()
          }}
        />
      )}
    </div>
  )
})
