import { usePatchRenameChatByIdMutation } from '@/shared/api/chat'
import { useEffect, useRef, useState } from 'react'

export const useChatRename = (
  chatId: string,
  initialTitle: string
) => {
  const [isBeingRenamed, setIsBeingRenamed] = useState(false)
  const [newTitle, setNewTitle] = useState(initialTitle)
  const inputRef = useRef<HTMLInputElement>(null)

  const [renameChat] = usePatchRenameChatByIdMutation()

  const handleRenameChat = async () => {
    if (newTitle.trim() === initialTitle) {
      setIsBeingRenamed(false) // No need to update if unchanged
      return
    }
    try {
      await renameChat({
        id: chatId,
        title: newTitle,
      }).unwrap()
      setIsBeingRenamed(false) // Exit rename mode
    } catch (error) {
      console.error('Failed to rename chat:', error)
    }
  }

  // Auto-focus input when renaming
  useEffect(() => {
    if (isBeingRenamed) {
      inputRef.current?.focus()
    }
  }, [isBeingRenamed])

  return {
    isBeingRenamed,
    newTitle,
    setNewTitle,
    setIsBeingRenamed,
    inputRef,
    handleRenameChat,
  }
}
