import IconExport from '@/shared/assets/icons/icon_export.svg?react'
import { MenubarItem, Typography } from '@/shared/ui'
import { useToast } from '@/shared/ui/Toast/useToast'

interface MenubarOpenDocumentInNewTabProps {
  fileId: number
}

export const MenubarOpenDocumentInNewTab = ({
  fileId,
}: MenubarOpenDocumentInNewTabProps) => {
  const { toast } = useToast()

  return (
    <MenubarItem
      asChild
      onClick={() => {
        const url = `/documents/${fileId}`
        const fullUrl = window.location.origin + url

        const newTab = window.open(fullUrl, '_blank')
        if (!newTab) {
          toast({
            title: 'Ошибка',
            description:
              'Произошла ошибка при открытии документа в новом окне',
            variant: 'error',
          })
        }
      }}
      className="gap-2 rounded-lg p-2 hover:cursor-pointer hover:bg-base-100"
    >
      <div
        className="flex items-center justify-start gap-2 rounded-lg p-2
          hover:cursor-pointer hover:bg-base-100"
      >
        <IconExport className="h-5 w-5" />

        <Typography
          className="font-normal text-black"
          variant="label"
        >
          В новом окне
        </Typography>
      </div>
    </MenubarItem>
  )
}
