import { DocumentData } from '@/entities/document/types'
import { DocumentPanelSkeleton } from '@/entities/panel/ui/DocumentPanelSkeleton'
import { RawHtmlMainRenderer } from '@/entities/panel/ui/RawHtmlMainRenderer'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useEffect, useRef } from 'react'

interface DocumentVersionContainerProps {
  data: DocumentData | undefined
  isLoading: boolean
  isFetching: boolean
  index: number
}

export const DocumentVersionContainer = ({
  data,
  isLoading,
  isFetching,
  index,
}: DocumentVersionContainerProps) => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  const varsionDocumentRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (varsionDocumentRef.current) {
      varsionDocumentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [data, isLoading, isFetching])
  return (
    <div className="flex h-full w-full flex-col items-stretch justify-start bg-white">
      {data && data.content && !isLoading && !isFetching ? (
        <div
          className={`p relative h-[calc(100svh-170px)] overflow-y-auto rounded-t-[12px]
            border border-base-100 bg-white ${isMobile ? 'px-5' : 'px-16'} py-8
            shadow-shadow-modal-light`}
          ref={varsionDocumentRef}
        >
          <RawHtmlMainRenderer
            rawHtml={data.content}
            index={index}
            isVersions={true}
          />
        </div>
      ) : (
        <div
          className="h-[calc(100svh-170px)] w-full rounded-t-[12px] border border-base-100
            px-16 pt-8 shadow-shadow-modal-light"
        >
          <DocumentPanelSkeleton />
        </div>
      )}
    </div>
  )
}
