import { useDocuments } from '@/entities/document/models/useDocuments'
import { DocumentsToggleFavoritesPageButton } from '@/features/document'
import {
  DocumentStatusFilterMenuDesktop,
  SortModeFilterMenuDesktop,
} from '@/features/filters'
import { Typography } from '@/shared/ui'
import { useLocation } from 'react-router-dom'

export const WidgetDocumentFilters = () => {
  const { data } = useDocuments()

  const location = useLocation()
  const isFavoriteRoute = location.pathname === '/documents/favorite'

  const formattedTotalDocuments = new Intl.NumberFormat(
    'ru-RU'
  ).format(data?.meta?.total ?? 0)

  return (
    <div className="shrink-0 grow self-stretch border-b border-[#F0F0F0]">
      <div className="flex flex-nowrap items-center justify-between gap-3 px-3 py-2">
        <Typography
          variant={'label'}
          className="font-semibold text-base-400"
        >
          {`Найдено документов: ${formattedTotalDocuments}`}
        </Typography>
        <div className="inline-flex shrink-0 grow items-center justify-end gap-3">
          {/* <DesktopRangePicker
            totalDocuments={formattedTotalDocuments}
          /> */}
          {/* <DatePickerWithRange /> */}
          <DocumentStatusFilterMenuDesktop
            totalDocuments={formattedTotalDocuments}
          />
          <SortModeFilterMenuDesktop
            totalDocuments={formattedTotalDocuments}
          />
          <DocumentsToggleFavoritesPageButton
            isFavoriteRoute={isFavoriteRoute}
          />
        </div>
      </div>
    </div>
  )
}
