export type ISearchMode = 'IN_TITLE' | 'CONTEXT' | 'CONTEXT_PLUS'

//src/entities/document/types/index.ts
export enum EDocumentStatus {
  ACTIVE_WITHOUT_CHANGES = 'ACTIVE_WITHOUT_CHANGES',
  ACTIVE_WITH_CHANGES = 'ACTIVE_WITH_CHANGES',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}

export const enum EDocumentParagraphType {
  HEADING = 'HEADING',
  COMMENT = 'COMMENT',
  TEXT = 'TEXT',
}

export interface IDocument {
  id: number
  name: string
  requisites: string
  status: {
    code: EDocumentStatus
    name: string
  }
  is_favorite: boolean
  citation_paragraphs?: string[]
}

export type DocumentLanguages = 'ru' | 'kk' | 'en'

export interface Locale {
  current: DocumentLanguages
  available: DocumentLanguages[]
}

export interface Paragraph {
  id: number
  type: 'HEADING' | 'COMMENT' | 'TEXT'
  content: string
}

export interface Version {
  id: number
  published_at: string
}

export interface DocumentChapter {
  title: string
  paragraph_id: number
}

export interface DocumentFilter {
  id: number
  name: string
  document_amount: number
  children: DocumentFilter[] | []
}

export interface DocumentQueryParams {
  search_mode?: ISearchMode
  search_query?: string
  group?: string // Comma-separated values (e.g., '1,2,3')
  type?: string // Comma-separated values (e.g., '1,2,3')
  developing_organization?: string // Comma-separated values (e.g., '1,2,3')
  approving_organization?: string
  status?: 'ACTIVE' | 'PENDING'
  created_at?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-15')
  created_at_from?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-01')
  created_at_until?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-30')
  page?: number // Optional page number for pagination
}

export interface FavoriteDocumentQueryParams {
  search_mode?: ISearchMode
  search_query?: string
  status?: 'ACTIVE' | 'PENDING'
  created_at?: string
  created_at_from?: string
  created_at_until?: string
  page?: number
}

export interface DocumentData {
  id: number
  name: string
  requisites: string
  status: {
    code: EDocumentStatus
    name: string
  }
  locale: Locale
  paragraphs: Paragraph[]
  versions: Version[]
  content: string
}

export interface Meta {
  //TODO: Вынести Meta в shared/api
  total: number
  per_page: number
  current_page: number
  next_page: number | null
  last_page: number
}

export interface DocumentBaseMenuItem
  extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode
  title: string
}
