import IconContentDark from '@/shared/assets/icons/icon_content_dark.svg?react'
import { IconButton } from '@/shared/ui'

import { useDocumentChaptersAction } from '../../hooks/useDocumentChapters'

export const ActionDocumentChapters = () => {
  const {
    chaptersData,
    chaptersError,
    isLoadingChapters,
    isFetchingChapters,
    isDocumentChaptersOpen,
    toggleDocumentChaptersOpen,
    toggleDocumentChaptersClose,
  } = useDocumentChaptersAction()

  if (
    isFetchingChapters ||
    isLoadingChapters ||
    !chaptersData ||
    chaptersError
  ) {
    return (
      <IconButton className="h-8 w-8 p-2 hover:bg-base-100" disabled>
        <IconContentDark className="h-6 w-6" />
      </IconButton>
    )
  }

  return (
    <>
      <IconButton
        className="h-8 w-8 p-2 hover:bg-base-100"
        onClick={
          !isDocumentChaptersOpen
            ? toggleDocumentChaptersOpen
            : toggleDocumentChaptersClose
        }
      >
        <IconContentDark className="h-6 w-6" />
      </IconButton>
    </>
  )
}
