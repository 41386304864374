import { usePostToggleFavoriteDocumentMutation } from '@/shared/api/documents'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useState } from 'react'

export const useToogleFavouriteDocument = (documentId: number) => {
  const [toggleFavorite, { isLoading, isSuccess, isError }] =
    usePostToggleFavoriteDocumentMutation()
  const { toast } = useToast()
  const handleToggleFavorite = async () => {
    try {
      await toggleFavorite({ document_id: documentId }).unwrap()
    } catch (error) {
      toast({
        title: 'Ошибка',
        variant: 'error',
        description:
          'Не удалось добавить в избранное. Попробуйте позднее',
      })
    }
  }
  const [isBookmarkHovered, setIsBookmarkHovered] = useState(false)

  const handleMouseEnter = () => setIsBookmarkHovered(true)
  const handleMouseLeave = () => setIsBookmarkHovered(false)
  const handleClick = () => setIsBookmarkHovered(false)

  return {
    isBookmarkHovered,
    handleMouseEnter,
    handleMouseLeave,
    handleToggleFavorite,
    isLoading,
    isSuccess,
    handleClick,
    isError,
  }
}
