import { IChatMessage } from '@/entities/chat'
import {
  ButtonCopyAiResponse,
  ButtonToggleFavoriteMessage,
} from '@/features/chat'
import IconCodexGray from '@/shared/assets/icons/icon_codex_gray.svg?react'
import IconLess from '@/shared/assets/icons/icon_screen_less.svg?react'
import IconMore from '@/shared/assets/icons/icon_screen_more.svg?react'
import { Button } from '@/shared/ui'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { ChatAiFile } from '@/widgets/Chat/ChatAiFile'
import { HTMLAttributes, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'

interface ChatAiResponseProps extends HTMLAttributes<HTMLDivElement> {
  response: IChatMessage
  isLastResponse: boolean
}

export const ChatAiResponse = ({
  response,
  isLastResponse,
  ...props
}: ChatAiResponseProps) => {
  const { chat_id } = useParams()

  const [active, setActive] = useState(false)
  const [showAllDocuments, setShowAllDocuments] = useState(false)

  useEffect(() => {
    requestAnimationFrame(() => {
      setActive(true)
    })
  }, [])
  return (
    <div
      className={cn(
        'group flex items-stretch justify-start gap-4 self-stretch rounded-lg',
        'ai-response-appear',
        active && 'ai-response-appear-active'
      )}
      {...props}
    >
      <IconButton
        className="flex max-h-8 max-w-8 items-center justify-center rounded-full
          border-[1px] border-base-200 bg-white p-[6px]"
      >
        <IconCodexGray />
      </IconButton>
      <div className="flex flex-col items-stretch justify-start gap-4">
        <Typography className="whitespace-normal break-words">
          <ReactMarkdown className={'markdown -my-4'}>
            {response.message ?? ''}
          </ReactMarkdown>
        </Typography>
        {response.documents && (
          <div className="flex flex-col items-stretch justify-start gap-2">
            {response.documents.length > 0 && (
              <>
                {/* Show either all documents if there are 5 or fewer, or the first 5 if more */}
                {response.documents
                  .slice(
                    0,
                    showAllDocuments ? response.documents.length : 5
                  )
                  .map((file) => {
                    const fileKey = `${file.id + response.id}`
                    return <ChatAiFile file={file} key={fileKey} />
                  })}
              </>
            )}
          </div>
        )}

        <div className="flex items-center gap-4">
          {response.documents && response.documents.length > 5 && (
            <Button
              onClick={() => setShowAllDocuments(!showAllDocuments)}
              className={cn(
                `flex w-[189px] items-center gap-2 rounded-3xl border
                border-stroke-light-gray-2 py-[6px] pl-4 pr-6 text-base-950
                shadow-shadow8 hover:shadow-shadow8`,
                showAllDocuments ? 'bg-base-100' : 'bg-white'
              )}
            >
              <IconButton>
                {showAllDocuments ? <IconLess /> : <IconMore />}
              </IconButton>
              <Typography
                variant={'label'}
                className="whitespace-nowrap"
              >
                Показать {showAllDocuments ? 'меньше' : 'больше'}
              </Typography>
            </Button>
          )}
          <div
            className={cn(
              'flex items-center justify-start self-stretch opacity-0',
              'mt-auto transition-opacity group-hover:opacity-100',
              isLastResponse && 'opacity-100'
            )}
          >
            <ButtonCopyAiResponse text={response.message ?? ''} />
            <ButtonToggleFavoriteMessage
              chatId={chat_id ? chat_id : response.chat?.id}
              isFavorite={response.is_favorite}
              messageId={String(response.id)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
