import { EDocumentStatus } from '../types'

type DocumentStatusBadge = {
  label: string
  color: string
  textColor: string
}

export const documentStatusBadgeVariants: Record<
  EDocumentStatus,
  DocumentStatusBadge
> = {
  [EDocumentStatus.ACTIVE_WITHOUT_CHANGES]: {
    label: 'Действующий',
    color: '#EDF3EC',
    textColor: '#4A6F47',
  },
  [EDocumentStatus.ACTIVE_WITH_CHANGES]: {
    label: 'Обновлен',
    color: '#EDF3EC',
    textColor: '#4A6F47',
  },
  [EDocumentStatus.PENDING]: {
    label: 'Не введен в действие',
    color: '#FDEBEC',
    textColor: '#8F5752',
  },
  [EDocumentStatus.SUSPENDED]: {
    label: 'Приостановлен в действии',
    color: '#FCECD6',
    textColor: '#845F34',
  },
  [EDocumentStatus.CANCELED]: {
    label: 'Утративший силу',
    color: '#EDEEF1',
    textColor: '#67707E',
  },
  [EDocumentStatus.EXPIRED]: {
    label: 'Прекратил действие',
    color: '#FDEBEC',
    textColor: '#8F5752',
  },
}
