import IconBookBlue from '@/shared/assets/icons/icon_book_blue.svg?react'
import IconBookGray from '@/shared/assets/icons/icon_book_gray.svg?react'
import { Typography } from '@/shared/ui'
import { motion } from 'framer-motion'

const containerVariantsPeach = {
  rest: { backgroundColor: 'transparent' },
  hover: {
    backgroundColor: '#E7EFFE',
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
}

const iconGrayVariants = {
  rest: { opacity: 1 },
  hover: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'easeInOut' },
  },
}

const iconColorVariants = {
  rest: { opacity: 0 },
  hover: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeInOut' },
  },
}
export const SidebarFooter = () => {
  return (
    <div
      className="absolute bottom-0 z-[50] w-full flex-none flex-col items-stretch
        justify-start rounded-b-[12px] border-t border-t-base-100 bg-[#FCFCFC]
        p-2 backdrop-blur-[8px] hover:text-[#4D8EFF]"
    >
      {/* First Item */}
      <motion.div
        className="group flex cursor-pointer items-center justify-start gap-2 rounded-lg
          p-2 hover:text-[#4D8EFF]"
        variants={containerVariantsPeach}
        initial="rest"
        whileHover="hover"
      >
        <div className="relative h-5 w-5">
          <motion.div
            variants={iconGrayVariants}
            className="absolute inset-0"
          >
            <IconBookGray />
          </motion.div>
          <motion.div
            variants={iconColorVariants}
            className="absolute inset-0"
          >
            <IconBookBlue />
          </motion.div>
        </div>
        <Typography
          variant={'label'}
          className="group-hover:text-[#4D8EFF]"
        >
          Гид по функциям
        </Typography>
      </motion.div>
    </div>
  )
}
