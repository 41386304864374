import { useCallback, useEffect, useRef } from 'react'

export const useIntersectionObserver = ({
  onIntersect,
  threshold = 1.0,
}: {
  onIntersect: (isVisible: boolean) => void
  threshold?: number
}) => {
  const observerRef = useRef<IntersectionObserver | null>(null)

  const setObservedElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      // Disconnect old observer if present
      if (observerRef.current) observerRef.current.disconnect()

      if (node) {
        // Create new observer
        const observer = new IntersectionObserver(
          (entries) => {
            const isFullyVisible = entries.some(
              (entry) => entry.isIntersecting
            )
            onIntersect(isFullyVisible)
          },
          { threshold }
        )
        observer.observe(node)
        observerRef.current = observer
      }
    },
    [onIntersect, threshold]
  )

  useEffect(() => {
    return () => {
      if (observerRef.current) observerRef.current.disconnect()
    }
  }, [])

  return setObservedElementRef
}
