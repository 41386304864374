import { Version } from '@/entities/document'
import IconCheck from '@/shared/assets/icons/icon_check_dark.svg?react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'

interface DocumentVersionSelectMobileProps {
  onSelectChange: (value: string) => void
  versions: Version[]
  currentVersion: Version
}

export const DocumentVersionSelectMobile = ({
  onSelectChange,
  versions,
  currentVersion,
}: DocumentVersionSelectMobileProps) => {
  return (
    <Select onValueChange={onSelectChange}>
      <SelectTrigger
        className={cn(
          'h-[36px] w-full rounded-lg px-3 py-1 ring-transparent focus:ring-0',
          `border-[1px] data-[state=open]:border-additional-blue-2
          data-[state=open]:outline-[0] data-[state=open]:ring-0`
        )}
      >
        {currentVersion.published_at && (
          <Typography variant={'label'}>
            {currentVersion.published_at}
          </Typography>
        )}
      </SelectTrigger>
      <SelectContent className="z-[100] max-h-[164px] max-w-[217px] rounded-lg">
        {versions.map((ver) => {
          const isSelected = ver.id === currentVersion.id
          return (
            <SelectItem
              value={ver.id.toString()}
              className={cn(
                isSelected && 'bg-base-100',
                'max-w-[205px]'
              )}
              key={ver.id}
            >
              <div className="flex w-full items-center justify-between pr-[3px]">
                <Typography variant={'label'}>
                  {ver.published_at}
                </Typography>
                {isSelected && <IconCheck />}
              </div>
            </SelectItem>
          )
        })}
      </SelectContent>
    </Select>
  )
}
