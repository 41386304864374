import {
  DocumentContentsProvider,
  DocumentVersionsMenuMobile,
} from '@/features/document'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { WidgetDocumentVersionsContents } from '@/widgets/DocumentByIdPage/WidgetDocumentVersionsContents'
import { WidgetDocumentVersionsContentsMobile } from '@/widgets/DocumentByIdPageMobile/WidgetDocumentVersionsContentsMobile'

export const DocumentByIdVersionsPage = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isMobile) {
    return (
      <DocumentContentsProvider>
        <div className="min-w-80">
          <WidgetDocumentVersionsContentsMobile />
        </div>
      </DocumentContentsProvider>
    )
  }

  return (
    <DocumentContentsProvider>
      <div
        className="relative flex h-[calc(100svh-96px)] w-full items-stretch justify-start
          gap-8 px-[70px] xs:px-0 md:px-0"
      >
        <WidgetDocumentVersionsContents />
      </div>
    </DocumentContentsProvider>
  )
}
