import { EDocumentStatus } from '@/entities/document'
import { documentStatusBadgeVariants } from '@/entities/document/consts'
import IconDocumentsOutlinedDark from '@/shared/assets/icons/icon_documents_outlined_dark.svg?react'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'

import { DocumentStatus } from '../../types'

interface DocumentStatusItemProps {
  items: DocumentStatus[]
  selectedIndices: EDocumentStatus[]
  handleCheckboxChange: (code: EDocumentStatus) => void
}

export const DocumentStatusFilterItemsDesktop: React.FC<
  DocumentStatusItemProps
> = ({ items, selectedIndices, handleCheckboxChange }) => {
  return (
    <>
      {Object.values(EDocumentStatus).map((status, index) => {
        const checkboxId = `checkbox-${status}`

        return (
          <label
            key={index}
            htmlFor={checkboxId}
            className="flex cursor-pointer flex-nowrap items-center justify-between gap-2
              self-stretch p-2"
          >
            <span
              className="flex shrink grow-0 basis-full items-center justify-start gap-2
                overflow-hidden"
            >
              <Checkbox
                variant={'secondary'}
                size={'small'}
                id={checkboxId}
                checked={selectedIndices.includes(status)}
                onCheckedChange={() => handleCheckboxChange(status)}
              />
              <Typography
                variant={'label'}
                className="inline min-w-0"
              >
                {documentStatusBadgeVariants[status].label}
              </Typography>
            </span>
          </label>
        )
      })}
    </>
  )
}
