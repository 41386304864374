import { IDocument } from '@/entities/document/types'
import {
  ActionDocumentBookmark,
  ActionDocumentDownload,
  ActionDocumentInfo,
} from '@/features/document'
import IconArrowThinkUp from '@/shared/assets/icons/icon_arrow_thick_up.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import {
  Menubar,
  MenubarContent,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { cn } from '@/shared/utils/common'
import { ReactNode } from 'react'

interface DocumentBaseItemMenuActionsProps {
  children?: ReactNode
  documentItem: IDocument
}

export const DocumentBaseItemMenuActions = ({
  children,
  documentItem,
}: DocumentBaseItemMenuActionsProps) => {
  const isFavorite = documentItem.is_favorite
    ? documentItem.is_favorite
    : false

  return (
    <>
      <Menubar className="flex items-start justify-stretch self-stretch border-none">
        <MenubarMenu>
          <MenubarTrigger
            className={cn(
              `absolute right-3 flex h-6 w-6 items-center justify-start rounded-lg
              bg-bg-light-white-1 hover:cursor-pointer hover:bg-bg-light-gray-2
              data-[state=open]:bg-base-100`
            )}
          >
            {children ? children : <IconMoreDotsDark />}
          </MenubarTrigger>

          <MenubarContent
            className="relative flex flex-col items-stretch rounded-[20px] bg-white p-2"
            align="end"
            // TODO: collision Boundary нужно будет обдумать
            style={{
              boxShadow:
                '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
            }}
            alignOffset={-12}
            sideOffset={4}
          >
            <IconArrowThinkUp className="absolute -top-[14px] right-[15px] h-5 w-5" />

            {/* Menu Items */}
            <ActionDocumentInfo
              documentId={String(documentItem.id)}
              withLabel
            />
            <ActionDocumentBookmark
              documentId={String(documentItem.id)}
              isFavorite={isFavorite}
              withLabel
            />
            <ActionDocumentDownload
              documentId={documentItem.id}
              withLabel
            />
            {/* TODO: Нужно добавить "В папку" когда функционал будет готов
            import IconFolderPlus from '@/shared/assets/icons/icon_folder_plus.svg?react'
          */}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </>
  )
}
