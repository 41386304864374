import IconInfoDark from '@/shared/assets/icons/icon_info_dark.svg?react'
import IconInfoItallicDark from '@/shared/assets/icons/icon_info_itallic_dark.svg?react'
import IconLettersDark from '@/shared/assets/icons/icon_letters_dark.svg?react'
import { MenubarItem, Typography } from '@/shared/ui'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

import { useDocumentInfoAction } from '../../hooks/useDocumentInfoAction'

interface ActionDocumentInfoProps {
  documentId?: string
  withLabel?: boolean
  isMobile?: boolean
}

export const ActionDocumentInfo = ({
  documentId,
  isMobile = false,
  withLabel = false,
}: ActionDocumentInfoProps) => {
  const { handleInfoClick } = useDocumentInfoAction({
    documentId: documentId ?? '',
  })

  if (isMobile) {
    return (
      <div
        onClick={handleInfoClick}
        className="flex cursor-pointer items-center justify-start gap-3 border-b
          border-b-stroke-light-gray-2 py-3"
      >
        <IconLettersDark className="min-h-6 min-w-6" />

        <Typography
          variant={'label'}
          className="text-text-light-black-8"
        >
          Информация о документе
        </Typography>
      </div>
    )
  }

  if (!withLabel) {
    return (
      <>
        <IconButton
          className="h-8 w-8 p-2 hover:bg-base-100"
          onClick={handleInfoClick}
        >
          <IconInfoItallicDark className="h-6 w-6" />
        </IconButton>
      </>
    )
  }
  return (
    <>
      <MenubarItem
        onClick={handleInfoClick}
        inset={true}
        className="p-0"
      >
        <div
          className={cn(
            `flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
            hover:bg-slate-100`
          )}
        >
          <IconInfoDark className="h-5 w-5" />
          <Typography
            className="text-nowrap font-normal"
            variant="label"
          >
            О документе
          </Typography>
        </div>
      </MenubarItem>
    </>
  )
}
