import { useDeleteChatByIdMutation } from '@/shared/api/chat'
import IconBucketBlack from '@/shared/assets/icons/icon_bucket_black.svg?react'
import { MenubarItem, Typography } from '@/shared/ui'
import { useNavigate } from 'react-router-dom'

interface MenubarDeleteChatProps {
  itemId: string
}
export const MenubarDeleteChat = ({
  itemId,
}: MenubarDeleteChatProps) => {
  const [deleteChat] = useDeleteChatByIdMutation()
  const navigate = useNavigate()

  const handleDelete = async () => {
    try {
      await deleteChat(itemId).unwrap()
      navigate('/')
    } catch (error) {
      console.error('Failed to delete chat:', error)
    }
  }

  return (
    <MenubarItem
      className="flex items-center justify-start gap-2 rounded-lg p-2
        hover:cursor-pointer hover:bg-slate-100"
      inset={true}
      onClick={(e) => {
        e.stopPropagation()
        handleDelete()
      }}
    >
      <IconBucketBlack className="h-5 w-5" />
      <Typography
        className="whitespace-nowrap font-normal"
        variant={'label'}
      >
        Удалить
      </Typography>
    </MenubarItem>
  )
}
