import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import React, { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CourtBaseMenuItem,
  ICourtItem,
} from '../../../entities/courtBase/types'
import { CourtBaseItemStatus } from '../../../entities/courtBase/ui/CourtBaseItemStatus'
import { CourtBaseDocumentActionsMenu } from '../CourtBaseDocumentActionsMenu'

interface CourtBaseItemProps {
  courtBaseItem: ICourtItem
  isIndexed?: boolean
  isLastItem: boolean
  index: number
}

export const CourtBaseItem = forwardRef<
  HTMLDivElement,
  CourtBaseItemProps
>(({ courtBaseItem, isLastItem, isIndexed = 'true', index }, ref) => {
  const navigate = useNavigate()
  const courtData = [
    { name: 'Истцы', data: courtBaseItem.plaintiffs },
    { name: 'Ответчики', data: courtBaseItem.defendants },
    { name: 'Суд', data: [courtBaseItem.court] },
    { name: 'Судьи', data: courtBaseItem.judges },
    { name: 'Требования', data: [courtBaseItem.requirements] },
  ]

  return (
    <>
      <div
        className={cn(
          // py-3 pl-4 pr-14
          isLastItem ? '' : 'border-b-[1px]',
          'relative flex gap-5 bg-white p-4'
        )}
        style={{
          boxShadow:
            '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
        }}
      >
        <Typography variant={'body'} className="font-medium">
          {index + 1}.
        </Typography>
        {/* Header */}
        <div
          className="relative flex w-full flex-col items-start justify-start"
          ref={ref}
        >
          <div
            className="flex w-full cursor-pointer items-stretch justify-between"
            onClick={() =>
              navigate(`/court-base/${courtBaseItem.id}`)
            }
          >
            {isIndexed && (
              <Typography variant={'body'} className="font-medium">
                Гражданское дело №{courtBaseItem.id}
              </Typography>
            )}
            <Typography variant={'body'} className="pr-4 font-medium">
              От {courtBaseItem.date}
            </Typography>
          </div>
          {/* Body */}
          <div className="grid grid-cols-[1fr_auto] gap-x-2.5 gap-y-2 pt-4">
            {courtData.map((court, index) => {
              const isLast = index + 1 === courtData.length
              return (
                <React.Fragment key={court.name}>
                  <Typography
                    variant={'label'}
                    className="align-center flex translate-y-0.5 font-medium text-base-900"
                  >
                    {court.name}:
                  </Typography>
                  <span
                    className={cn(
                      // py-3 pl-4 pr-14
                      isLast ? '' : 'pb-2',
                      'border-b-[1px]'
                    )}
                  >
                    {court.data.map((el, index) => {
                      const isFirst = index === 0

                      return (
                        <Typography
                          variant={'label'}
                          className="font-medium text-base-900"
                          key={index}
                        >
                          {isFirst ? null : '• '}
                          {el}
                        </Typography>
                      )
                    })}
                  </span>
                </React.Fragment>
              )
            })}
            <Typography
              variant={'label'}
              className="pt-2 font-medium text-base-900"
            >
              Решение:
            </Typography>
            <div className="flex items-center justify-start gap-2">
              {courtBaseItem.status.map((stat, index) => {
                const isLast =
                  index + 1 === courtBaseItem.status.length

                return (
                  <span key={index}>
                    <CourtBaseItemStatus status={stat} />
                    {!isLast && (
                      <Typography
                        variant={'label'}
                        className="pl-2 pt-px font-medium leading-[2px] text-base-300"
                      >
                        |
                      </Typography>
                    )}
                  </span>
                )
              })}
            </div>
          </div>
        </div>

        <CourtBaseDocumentActionsMenu courtBaseItem={courtBaseItem} />
      </div>
    </>
  )
})

CourtBaseItem.displayName = 'CourtBaseItem'
