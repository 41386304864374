import { useDocumentsToggleFavoritesPage } from '@/features/document'
import IconBookmarkDark from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import IconBookmarkSmall from '@/shared/assets/icons/icon_bookmark_small.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

type DocumentsToggleFavoritesPageButtonProps = {
  isFavoriteRoute: boolean
}

export const DocumentsToggleFavoritesPageButton = ({
  isFavoriteRoute,
}: DocumentsToggleFavoritesPageButtonProps) => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  const { handleBookmarkClick } = useDocumentsToggleFavoritesPage({
    isFavoriteRoute: isFavoriteRoute,
  })

  return (
    <IconButton
      className={cn(
        `flex items-center justify-center rounded-lg border
        border-stroke-light-gray-2`,
        isMobile ? 'h-10 w-10' : 'h-7 w-7',
        isFavoriteRoute && 'border-additional-blue-2'
      )}
      onClick={handleBookmarkClick}
    >
      {isMobile ? (
        <IconBookmarkDark
          className={cn(
            'min-h-6 min-w-6',
            isFavoriteRoute
              ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
              : ''
          )}
        />
      ) : (
        <IconBookmarkSmall
          className={cn(
            isFavoriteRoute
              ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
              : ''
          )}
        />
      )}
    </IconButton>
  )
}
