import { useChatHistoryBookmark } from '@/entities/chatHistory'
import { usePatchFavoriteChatByIdMutation } from '@/shared/api/chat'
import { useToast } from '@/shared/ui/Toast/useToast'

interface useToggleFavoriteChatProps {
  chatId: string
}

export const useToggleFavoriteChat = ({
  chatId,
}: useToggleFavoriteChatProps) => {
  const {
    isBookmarkHovered,
    handleMouseEnter,
    handleMouseLeave,
    handleClick,
  } = useChatHistoryBookmark()

  const { toast } = useToast()
  const [toggleFavoriteChat] = usePatchFavoriteChatByIdMutation()

  const handleToggleFavorite = async () => {
    try {
      await toggleFavoriteChat(chatId).unwrap()
    } catch (error) {
      toast({
        title: 'Ошибка',
        variant: 'error',
        description: 'Не удалось изменить избранное',
      })
    }
  }
  return {
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    isBookmarkHovered,
    handleToggleFavorite,
  }
}
