import IconCheck from '@/shared/assets/icons/icon_check_dark.svg?react'
import { ToastProps } from '@/shared/ui/Toast/toast'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useCallback, useState } from 'react'

interface useCopyToClipboardProps {
  withToast?: boolean
  toastOptions?: ToastProps
}
export const tooltipTexts = {
  copy: 'Копировать текст',
  copied: 'Скопировано!',
}

export const useCopyToClipboard = ({
  withToast = false,
  toastOptions,
}: useCopyToClipboardProps = {}) => {
  const { toast } = useToast()

  const [open, setOpen] = useState(false)
  const [tooltipText, setTooltipText] = useState(tooltipTexts.copy)

  const handleMouseEnter = useCallback(() => {
    setTooltipText(tooltipTexts.copy)
    setOpen(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setOpen(false)
  }, [])

  const copyToClipboard = useCallback(
    async (text: string) => {
      try {
        await navigator.clipboard.writeText(text)

        setTooltipText(tooltipTexts.copied)
        setOpen(true)

        if (withToast) {
          toast({
            icon: (
              <IconCheck className="[&_path]:fill-additional-green-2" />
            ),
            title: 'Успешно!',
            description: 'Текст скопирован в буфер обмена',
            variant: 'success',
            ...toastOptions,
          })
        }
      } catch (error) {
        toast({
          title: 'Ошибка копирования',
          description: 'Не удалось скопировать текст в буфер обмена',
          variant: 'error',
          ...toastOptions,
        })
      }
    },
    [withToast, toastOptions, toast]
  )

  return {
    open,
    setOpen,
    tooltipText,
    handleMouseEnter,
    handleMouseLeave,
    copyToClipboard,
  }
}
