'use client'

import IconRadioDot from '@/shared/assets/icons/icon_radio_dot.svg?react'
import { cn } from '@/shared/utils/common'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import * as React from 'react'

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        `flex aspect-square h-4 w-4 items-center justify-center rounded-full
        border-[2px] border-[#8D95A3] focus:outline-none
        disabled:cursor-not-allowed disabled:opacity-50
        data-[state=checked]:border-additional-blue-2
        data-[state=checked]:text-additional-blue-1`,
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <IconRadioDot className="h-2 w-2 fill-additional-blue-2 text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem }
