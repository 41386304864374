import { useDocumentChaptersAction } from '@/features/document/hooks/useDocumentChapters'
import IconExitLeft from '@/shared/assets/icons/icon_exit_left.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

export const WidgetDocumentChapters = () => {
  const {
    chaptersData,
    isDocumentChaptersOpen,
    handleDocumentChapterClick,
    toggleDocumentChaptersClose,
  } = useDocumentChaptersAction()

  if (!isDocumentChaptersOpen) {
    return null
  }

  // TODO: add accordion when data on backend will be fixed
  return (
    <div
      className="absolute left-2 top-3 z-50 flex h-[600px] w-[326px] flex-col
        items-center justify-start gap-4 overflow-hidden rounded-xl
        bg-bg-light-white-1 shadow-sidebarL"
    >
      {/* header */}
      <div
        className="flex w-full items-center justify-between border-b
          border-b-stroke-light-gray-1 bg-white px-4 py-3"
      >
        <Typography
          variant="subtitle"
          className="font-semibold text-text-light-black-4"
        >
          Содержание
        </Typography>
        <IconButton onClick={toggleDocumentChaptersClose}>
          <IconExitLeft />
        </IconButton>
      </div>
      {/* body */}
      <div className="-mt-4 w-full overflow-y-auto p-4 pt-4">
        {chaptersData?.chapters?.map((chapter, index) => (
          <>
            <div
              key={chapter.paragraph_id + index}
              onClick={() =>
                handleDocumentChapterClick(chapter.paragraph_id)
              }
              className={cn(
                'flex items-center gap-2 border-x border-base-100 p-2',
                index === 0
                  ? 'rounded-t-[8px] border-t border-base-100'
                  : '',
                index === chaptersData?.chapters.length - 1
                  ? 'rounded-b-[8px] border-b'
                  : ''
              )}
            >
              <Typography variant={'body'}>•</Typography>
              <Typography variant={'body'}>
                {chapter.title}
              </Typography>
            </div>
            {index < chaptersData?.chapters.length - 1 && (
              <div className="border-b border-base-100" />
            )}
          </>
        ))}
      </div>
    </div>
  )
}
