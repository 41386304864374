import {
  FavoriteChatMessagesMobile,
  IChatMessage,
} from '@/entities/chat'
import IconArrowScroll from '@/shared/assets/icons/icon_arrow_scroll.svg?react'
import { ButtonScrollToAnchor } from '@/shared/ui/ButtonScrollToAnchor'
import { cn } from '@/shared/utils/common'
import { MutableRefObject, useEffect } from 'react'

interface FavoriteChatContainerMobileProps {
  messages: IChatMessage[]
  isBottomVisible: boolean
  bottomRef: MutableRefObject<HTMLDivElement | null>
}

export const FavoriteChatContainerMobile = ({
  messages,
  bottomRef,
  isBottomVisible,
}: FavoriteChatContainerMobileProps) => {
  useEffect(() => {
    const lastMessageElement = document.getElementById('last-message')
    if (lastMessageElement) {
      lastMessageElement.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  if (!messages) return null

  return (
    <div
      className={`relative mx-5 flex min-h-0 flex-1 flex-col items-stretch justify-start
        overflow-y-auto pb-[88px] pt-16`}
    >
      <div
        className={cn(
          `relative mx-auto flex w-full flex-col items-stretch justify-start
          gap-8 self-center bg-[#FCFCFC] py-8 lg:max-w-[850px]`
        )}
      >
        <FavoriteChatMessagesMobile messages={messages} />
        <div ref={bottomRef} />
        {!isBottomVisible && (
          <ButtonScrollToAnchor
            className="fixed bottom-[104px] left-1/2 -translate-x-1/2 rounded-full border
              border-base-200 bg-white p-1"
            style={{
              boxShadow:
                '0px 18px 5px 0px rgba(0, 0, 0, 0.00), 0px 12px 5px 0px rgba(0, 0, 0, 0.00), 0px 7px 4px 0px rgba(0, 0, 0, 0.02), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
            }}
            targetRef={bottomRef}
          >
            <IconArrowScroll />
          </ButtonScrollToAnchor>
        )}
      </div>
    </div>
  )
}
