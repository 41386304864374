import {
  DocumentVersionContainer,
  DocumentVersionsMenu,
} from '@/features/document'
import { DocumentVersionsMenuMobile } from '@/features/document/ui/DocumentVersionsMenuMobile'
import { useCompareTwoVersions } from '@/shared/hooks/useCompareTwoVersions'
import { Typography } from '@/shared/ui'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const WidgetDocumentVersionsContentsMobile = () => {
  const {
    data,
    isLoading,
    isFetching,

    firstVerData,
    firstVerError,
    isFirstVerLoading,
    isFirstVerFetching,

    secondVerData,
    secondVerError,
    isSecondVerLoading,
    isSecondVerFetching,
  } = useCompareTwoVersions()
  const [searchParams] = useSearchParams()

  const [selectedVersionIdMobile, setSelectedVersionIdMobile] =
    useState<string>(searchParams.get('selected_version_id') || '')

  if (firstVerError || secondVerError) {
    return <Typography>Error...</Typography>
  }

  return (
    <div
      className="relative mx-auto flex h-[calc(100svh-96px)] w-full max-w-[1480px]
        flex-col items-stretch justify-start gap-3 xs:px-0 md:px-0"
    >
      <DocumentVersionsMenuMobile
        data={data?.document}
        firstVersionData={firstVerData?.document}
        secondVersionData={secondVerData?.document}
        isLoading={isLoading}
        isFetching={isFetching}
        selectedVersionIdMobile={selectedVersionIdMobile}
        setSelectedVersionIdMobile={setSelectedVersionIdMobile}
      />

      <div className="flex gap-3">
        <DocumentVersionContainer
          data={
            selectedVersionIdMobile ===
            String(firstVerData?.document.id)
              ? firstVerData?.document
              : secondVerData?.document
          }
          index={1}
          isLoading={isFirstVerLoading || isSecondVerLoading}
          isFetching={isFirstVerFetching || isSecondVerFetching}
        />
      </div>
    </div>
  )
}
