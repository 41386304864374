import IconSmallcapsDarkMedium from '@/shared/assets/icons/icon_smallcaps_dark_medium.svg?react'
import { IconButton } from '@/shared/ui'

export const ActionDocumentToLowerCase = () => {
  //   TODO: implement to lower case logic
  return (
    <IconButton className="h-8 w-8 p-2 hover:bg-base-100" disabled>
      <IconSmallcapsDarkMedium className="h-6 w-6" />
    </IconButton>
  )
}
