import IconArrowRightWhite from '@/shared/assets/icons/icon_arrow_right_white.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/shared/ui/DropdownMenu'
import { Typography } from '@/shared/ui/Typography'

import { mockSortModeData } from '../../consts'
import { useSortModeFilter } from '../../hooks/useSortModeFilter'
import { ISortMode } from '../../types'
import { SortModeFilterCustomInput } from '../SortModeFilterCustomInput'
import { SortModeFilterItemDesktop } from '../SortModeFilterItemDesktop'

type SortModeFilterDesktopProps = {
  totalDocuments?: string
}
export const SortModeFilterMenuDesktop = ({
  totalDocuments,
}: SortModeFilterDesktopProps) => {
  const {
    selectedSortMode,
    handleSortModeChange,
    handleApply,
    handleReset,
  } = useSortModeFilter()

  return (
    <DropdownMenu>
      <SortModeFilterCustomInput onRemove={handleReset} />
      <DropdownMenuContent
        align="center"
        className="relative m-0 flex h-[238px] w-[320px] flex-col gap-1 overflow-hidden
          rounded-2xl border border-stroke-light-gray-1 bg-bg-light-white-2 p-0
          shadow-popoverL"
      >
        {/* Header */}
        <div className="px-2 pt-2">
          <div className="flex items-center gap-2 pl-3">
            <Typography
              variant="subtitle"
              className="flex-1 font-medium text-text-light-black-3"
            >
              Выбрано фильтров: {selectedSortMode ? 1 : 0}
            </Typography>
            <button
              className="rounded-full px-3 py-0 text-black"
              onClick={handleReset}
            >
              <Typography
                variant="subtitle"
                className="text-[#25272C] underline"
              >
                Очистить
              </Typography>
            </button>
          </div>
        </div>
        {/* Sort modes */}
        <div
          className="flex flex-1 flex-col items-stretch justify-start gap-1 overflow-y-auto
            px-2"
        >
          <SortModeFilterItemDesktop
            items={mockSortModeData}
            selectedSortMode={selectedSortMode}
            handleSortModeChange={handleSortModeChange}
          />
        </div>
        {/* Footer */}
        <div className="flex flex-col gap-2 border-t border-t-[#F0F0F0] bg-white p-2">
          <Typography
            variant="subtitle"
            className="self-stretch px-3 font-medium text-text-light-black-5"
          >
            {totalDocuments &&
              `Найдено документов: ${totalDocuments}`}
          </Typography>
          <DropdownMenuItem className="p-0">
            <Button
              disabled={!selectedSortMode}
              onClick={handleApply}
              variant="secondary"
              leftIcon={<IconArrowRightWhite />}
              className="grow py-[10px] pl-4 pr-6"
            >
              Показать
            </Button>
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
