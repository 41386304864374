import IconChevronDown from '@/shared/assets/icons/icon_chevron_down.svg?react'
import IconCloseRound from '@/shared/assets/icons/icon_close_round.svg?react'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { useSearchParams } from 'react-router-dom'

interface CustomInputProps {
  onRemove?: () => void
  className?: string
}

const CustomInput: ForwardRefRenderFunction<
  HTMLButtonElement,
  CustomInputProps
> = ({ className, onRemove, ...props }, ref) => {
  const [searchParams] = useSearchParams()
  const statusSelected = searchParams.has('status')
  return (
    <div className="relative flex max-h-7">
      <button
        ref={ref}
        className={cn(
          'flex gap-3 rounded-lg border bg-bg-light-white-1 px-3 py-1',
          statusSelected
            ? 'border-[#4D8EFF]'
            : 'border-stroke-light-gray-2',
          className
        )}
        {...props}
      >
        <Typography className="font-Inter whitespace-nowrap text-sm font-medium leading-[20px]">
          Статус
        </Typography>
        <IconChevronDown className="mt-[2px]" />
      </button>
      {statusSelected && (
        <IconCloseRound
          className="absolute right-0 top-0 z-50 h-[14px] w-[14px] -translate-y-[7px]
            translate-x-[7px] transition-transform hover:scale-110
            hover:cursor-pointer"
          onClick={(e) => {
            e.stopPropagation()
            onRemove?.()
          }}
        />
      )}
    </div>
  )
}

export const DocumentStatusFilterCustomInput = forwardRef(CustomInput)
