import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { DocumentBaseContainer } from '@/widgets/DocumentsBase/DocumentBaseContainer'
import { DocumentBaseContainerMobile } from '@/widgets/DocumentsBase/DocumentBaseContainerMobile'

export const DocumentsBasePage = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isMobile) {
    return <DocumentBaseContainerMobile />
  }

  return <DocumentBaseContainer />
}
