import { ISearchMode } from '@/entities/document'
import {
  searchModeConfig,
  SearchModeFilterMenuDesktop,
  useDocumentsSearchInput,
} from '@/features/filters'
import IconCloseGray from '@/shared/assets/icons/icon_close_gray.svg?react'
import IconSearch from '@/shared/assets/icons/icon_search_grey.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import React, {
  ForwardedRef,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

interface DocumentsSearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputType?: string
  onValueChange?: (value: string) => void
  innerRef?: ForwardedRef<HTMLInputElement>
}

export const DocumentsSearchInput: React.FC<
  DocumentsSearchInputProps
> = ({
  inputType = 'text',
  onValueChange,
  innerRef,
  className,
  ...props
}) => {
  const {
    value,
    handleChange,
    handleKeyDown,
    handleReset,
    handleSubmit,
  } = useDocumentsSearchInput(onValueChange)

  const [searchParams] = useSearchParams()
  const searchMode = searchParams.get('search_mode') ?? 'IN_TITLE'

  const menuContainerRef = useRef<HTMLDivElement>(null)

  const [menuWidth, setMenuWidth] = useState(216) // default min-w

  useEffect(() => {
    if (menuContainerRef.current) {
      const actualWidth = menuContainerRef.current.offsetWidth
      setMenuWidth(actualWidth)
    }
  }, [searchMode])

  return (
    <div className="relative flex w-full items-start gap-2 rounded-t-3xl px-1">
      <div
        ref={menuContainerRef}
        className="absolute left-2 top-1 flex flex-col items-start gap-[3px]"
      >
        <SearchModeFilterMenuDesktop />
      </div>

      <input
        type={inputType}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={innerRef}
        style={{
          paddingLeft: `${menuWidth + 12}px`,
        }}
        placeholder={
          searchModeConfig[searchMode as ISearchMode].placeholder ||
          'Поиск по названию документа...'
        }
        className={cn(
          `border-input placeholder:font-inter flex h-[40px] w-full
          rounded-[20px] border bg-bg-light-gray-2 pr-16 text-sm shadow-sm
          transition-colors file:border-0 file:bg-transparent file:text-sm
          file:font-medium placeholder:text-sm placeholder:font-medium
          placeholder:leading-5 placeholder:text-[#67707E]
          focus-visible:outline-none focus-visible:ring-0
          focus-visible:ring-base-900 disabled:cursor-not-allowed
          disabled:opacity-50`,
          className
        )}
        {...props}
      />
      <IconButton
        onClick={handleSubmit}
        className={cn(
          `absolute h-8 w-8 translate-y-[4px] transition-all duration-300
          ease-in-out hover:scale-105`,
          value ? 'right-10' : 'right-3'
        )}
      >
        <IconSearch className="h-5 w-5" />
      </IconButton>
      <IconButton
        onClick={handleReset}
        className={cn(
          `absolute right-3 h-8 w-8 translate-y-[4px] transition-all duration-300
          ease-in-out hover:scale-105`,
          value ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
        )}
      >
        <IconCloseGray className="h-5 w-5 [&_path]:fill-[#67707E]" />
      </IconButton>
    </div>
  )
}
