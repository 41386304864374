import IconFolderPlus from '@/shared/assets/icons/icon_folder_plus.svg?react'
import { MenubarItem, Typography } from '@/shared/ui'

interface MenubarAddDocumentToFolderProps {
  fileId: number
}
export const MenubarAddDocumentToFolder = ({
  fileId,
}: MenubarAddDocumentToFolderProps) => {
  return (
    <MenubarItem
      disabled
      className="mt-1 flex items-center justify-start gap-3 rounded-b-lg border-t-[1px]
        border-stroke-light-gray-1 p-2 hover:cursor-pointer hover:bg-base-100"
      //   TODO: Add addToFolder functionality when it is ready on the backend
      onClick={() => console.log(`add ${fileId} to folder`)}
      inset={true}
    >
      <IconFolderPlus className="h-5 w-5" />
      <Typography
        variant="label"
        className="whitespace-nowrap font-normal text-black"
      >
        В папку
      </Typography>
    </MenubarItem>
  )
}
