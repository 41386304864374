import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { DocumentBaseFavoritesContainer } from '@/widgets/DocumentsBase/DocumentBaseFavoritesContainer'
import { DocumentBaseFavoritesContainerMobile } from '@/widgets/DocumentsBase/DocumentBaseFavoritesContainerMobile'

export const DocumentsFavoritePage = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isMobile) {
    return <DocumentBaseFavoritesContainerMobile />
  }

  return <DocumentBaseFavoritesContainer />
}
