import { Version } from '@/entities/document'
import {
  ActionDocumentBookmark,
  ActionDocumentChapters,
  ActionDocumentDownload,
  ActionDocumentInfo,
  ActionDocumentMagnify,
  ActionDocumentOpenVersionsSelect,
  ActionDocumentToLowerCase,
  ActionShowDocumentChunks,
  DocumentLanguageMenu,
  useDocumentMenuActions,
} from '@/features/document'
import IconBurger from '@/shared/assets/icons/icon_burger_gray.svg?react'
import { IconButton } from '@/shared/ui'
import { DocumentInfoDialog } from '@/widgets/Document/DocumentInfoDialog'
import { motion } from 'framer-motion'

interface DocumentMenuActionsProps {
  documentId: string | undefined
  versions: Version[]
  languages: string[]
}

export const WidgetDocumentMenuActions = ({
  documentId,
  versions,
  languages,
}: DocumentMenuActionsProps) => {
  const {
    isOpen,
    toggleOpen,
    handleChangeMainDocumentLanguage,
    language,
  } = useDocumentMenuActions()

  return (
    <>
      <DocumentInfoDialog />
      <div className="flex flex-col items-center justify-start gap-3">
        <IconButton
          className="h-10 w-10 rounded-full border border-stroke-light-gray-1
            bg-bg-light-white-1 p-[10px] shadow-button"
          onClick={(e) => toggleOpen(e)}
          aria-expanded={isOpen}
        >
          <IconBurger className="h-5 w-5 [&_path]:stroke-[#25272C]" />
        </IconButton>

        <motion.div
          className="!box-border flex w-12 flex-col items-center justify-start gap-4
            overflow-hidden rounded-[16px] border-[1px] border-base-100
            bg-bg-light-white-1 p-2 shadow-sidebarS"
          initial={{ opacity: 0, height: 0 }}
          animate={
            isOpen
              ? { opacity: 1, height: 'auto' }
              : { opacity: 0, height: 0 }
          }
          transition={{
            height: { duration: 0.3, ease: 'easeInOut' },
            opacity: { duration: 0.2 },
          }}
        >
          <ActionDocumentMagnify />
          <ActionDocumentChapters />
          <ActionShowDocumentChunks />
          {languages.length && (
            <DocumentLanguageMenu
              currentLanguage={
                language ? language.toUpperCase() : languages[0]
              }
              languages={languages}
              handleChange={handleChangeMainDocumentLanguage}
            />
          )}
          <ActionDocumentToLowerCase />
          <ActionDocumentOpenVersionsSelect versions={versions} />
          <ActionDocumentInfo documentId={documentId} />
          <ActionDocumentBookmark documentId={documentId} />
          <ActionDocumentDownload />
        </motion.div>
      </div>
    </>
  )
}
