import { cn } from '@/shared/utils/common'

interface ChatRenameInputProps {
  newTitle: string
  setNewTitle: (title: string) => void
  handleRenameChat: () => void
  setIsBeingRenamed: (state: boolean) => void
  inputRef: React.RefObject<HTMLInputElement>
}

export const ChatRenameInput = ({
  newTitle,
  setNewTitle,
  handleRenameChat,
  setIsBeingRenamed,
  inputRef,
}: ChatRenameInputProps) => {
  return (
    <input
      ref={inputRef}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleRenameChat()
        } else if (e.key === 'Escape') {
          setIsBeingRenamed(false)
        }
      }}
      value={newTitle}
      onChange={(e) => setNewTitle(e.target.value)}
      className={cn(
        `w-full bg-inherit px-1 py-[2px] text-[14px] font-normal leading-[20px]
        text-base-950`,
        `outline-none focus:border focus:border-additional-light-blue-2
        focus:ring-0`
      )}
      onBlur={() => setIsBeingRenamed(false)} // Exit rename mode on blur
    />
  )
}
