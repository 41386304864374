import { useTypedSelector } from '@/shared/store'
import {
  clearShowDocumentInfo,
  setShowDocumentInfo,
} from '@/shared/store/slices/documents/documentsSlice'
import { useDispatch } from 'react-redux'

interface UseDocumentInfoActionProps {
  documentId?: string
}

export const useDocumentInfoAction = ({
  documentId,
}: UseDocumentInfoActionProps) => {
  const dispatch = useDispatch()
  const details = useTypedSelector(
    (state) => state.documents.showDocumentInfo
  )

  const handleInfoClick = () => {
    if (documentId) {
      dispatch(setShowDocumentInfo(documentId))
    }
  }

  const handleInfoClose = () => {
    dispatch(clearShowDocumentInfo())
  }

  return {
    isInfoDialogOpen: !!details,
    handleInfoClick,
    handleInfoClose,
  }
}
