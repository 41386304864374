import {
  DocumentQueryParams,
  EDocumentStatus,
  ISearchMode,
} from '../types'

export const isDocumentStatus = (
  status: any
): status is { code: EDocumentStatus; name: string } => {
  return (
    status &&
    typeof status.code === 'string' &&
    Object.values(EDocumentStatus).includes(status.code) &&
    typeof status.name === 'string'
  )
}

export const buildDocumentQueryParams = (
  searchParams: URLSearchParams
): Partial<DocumentQueryParams> => {
  const queryParams = Object.fromEntries(searchParams.entries())
  const { search_mode, search_query } = queryParams

  // Build base query object
  const queryOptions: Partial<DocumentQueryParams> = {
    ...queryParams,
  }

  if (search_mode && search_query) {
    queryOptions.search_mode = search_mode as ISearchMode
    queryOptions.search_query = search_query
  } else if (search_mode && !search_query) {
    delete queryOptions.search_mode
    delete queryOptions.search_query
  } else if (!search_mode && search_query) {
    queryOptions.search_mode = 'IN_TITLE' as ISearchMode
    queryOptions.search_query = search_query
  }

  return queryOptions
}
