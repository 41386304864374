import { IChatHistoryItem } from '@/entities/chat/types'
import { useChatHistoryBookmark } from '@/entities/chatHistory'
import { usePatchFavoriteChatByIdMutation } from '@/shared/api/chat'
import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_black.svg?react'
import IconUnmark from '@/shared/assets/icons/icon_unmark.svg?react'
import { MenubarItem, Typography } from '@/shared/ui'
import { useToast } from '@/shared/ui/Toast/useToast'
import { cn } from '@/shared/utils/common'

import { useToggleFavoriteChat } from '../../models/useToggleFavoriteChat'

interface MenubarToggleFavoriteChatProps {
  item: IChatHistoryItem
}

export const MenubarToggleFavoriteChat = ({
  item,
}: MenubarToggleFavoriteChatProps) => {
  const {
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    handleToggleFavorite,
    isBookmarkHovered,
  } = useToggleFavoriteChat({ chatId: item.id })

  return (
    <MenubarItem
      key={`${item.id} favorite`}
      className="flex items-center justify-start gap-2 rounded-lg p-2
        hover:cursor-pointer hover:bg-slate-100"
      inset={true}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        // Also call the custom hook’s click handler to reset hover
        handleClick()
        e.stopPropagation()
        handleToggleFavorite()
      }}
    >
      {item.is_favorite && isBookmarkHovered ? (
        <IconUnmark className="h-5 w-5" />
      ) : (
        <IconBookmarkBlack
          className={cn(
            'h-5 w-5',
            item.is_favorite
              ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
              : ''
          )}
        />
      )}
      <Typography
        className="whitespace-nowrap font-normal"
        variant={'label'}
      >
        {item.is_favorite
          ? isBookmarkHovered
            ? 'Из избранных'
            : 'В избранных'
          : 'В избранное'}
      </Typography>
    </MenubarItem>
  )
}
