import { useGetDocumentChaptersQuery } from '@/shared/api/documents'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  closeChaptersMenu,
  openChaptersMenu,
} from '@/shared/store/slices/documents/documentsSlice'
import { toast } from '@/shared/ui/Toast/useToast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export const useDocumentChaptersAction = () => {
  const { id: documentId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isDocumentChaptersOpen = useTypedSelector(
    (state) => state.documents.chaptersMenuOpen
  )
  const toggleDocumentChaptersOpen = () => {
    dispatch(openChaptersMenu())
  }
  const toggleDocumentChaptersClose = () => {
    dispatch(closeChaptersMenu())
  }

  const {
    data: chaptersData,
    error: chaptersError,
    isFetching: isFetchingChapters,
    isLoading: isLoadingChapters,
  } = useGetDocumentChaptersQuery({ id: Number(documentId) })

  const handleDocumentChapterClick = (chapterId: number) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('anchorId', chapterId.toString())
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  if (chaptersError) {
    toast({
      variant: 'error',
      title: 'Что-то пошло не так',
      description:
        'Не удалось загрузить содержание документа. Попробуйте обновить страницу.',
    })
  }

  return {
    chaptersData,
    chaptersError,
    isLoadingChapters,
    isFetchingChapters,
    isDocumentChaptersOpen,
    toggleDocumentChaptersOpen,
    handleDocumentChapterClick,
    toggleDocumentChaptersClose,
  }
}
