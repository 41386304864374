import IconBookmarkGray from '@/shared/assets/icons/icon_bookmark_gray.svg?react'
import IconBurgerGray from '@/shared/assets/icons/icon_burger_gray.svg?react'
import IconNewChatGray from '@/shared/assets/icons/icon_new_chat_gray.svg?react'
import { IconButton } from '@/shared/ui'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui/Tooltip'
import { useNavigate } from 'react-router-dom'

import { useSidebar } from '../../model/useSidebar'

interface SidebarHeaderProps {
  isFavoritesPage: boolean
  handleFavoritesToggled: () => void
}

export const SidebarHeader = ({
  isFavoritesPage,
  handleFavoritesToggled,
}: SidebarHeaderProps) => {
  const { toggleSidebar } = useSidebar()
  const navigate = useNavigate()

  return (
    <div
      className="mb-4 flex flex-none items-center justify-between rounded-t-3xl
        border-b border-base-100 bg-[#FCFCFC] px-4 py-3 backdrop-blur-4px"
    >
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            className="rounded-full"
            onClick={toggleSidebar}
          >
            <IconBurgerGray width={24} height={24} />
          </IconButton>
        </TooltipTrigger>
        <TooltipContent side="top">Скрыть меню</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            onClick={() => navigate('/')}
            className="rounded-full"
          >
            <IconNewChatGray width={24} height={24} />
          </IconButton>
        </TooltipTrigger>
        <TooltipContent side="top">Новый диалог</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            onClick={() => {
              handleFavoritesToggled()
            }}
          >
            <IconBookmarkGray
              width={24}
              height={24}
              fill={isFavoritesPage ? '#4D8EFF' : 'none'}
              className={
                isFavoritesPage
                  ? '[&>path]:stroke-[#4D8EFF]'
                  : '[&>path]:stroke-[#67707E]'
              }
            />
          </IconButton>
        </TooltipTrigger>
        <TooltipContent side="top">Избранное</TooltipContent>
      </Tooltip>
    </div>
  )
}
