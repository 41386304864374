import { DialogDocumentVersionSelect } from '@/entities/document'
import { DocumentData } from '@/entities/document/types'
import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left_dark.svg?react'
import {
  Button,
  IconButton,
  RadioGroup,
  RadioGroupItem,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { Dispatch, SetStateAction } from 'react'

import { useDocumentVersionSelect } from '../../hooks/useDocumentVersionSelect'
import { DocumentVersionSelectMobile } from '../DocumentVersionSelectMobile'
import { DocumentVersionsMenuSkeleton } from '../DocumentVersionsMenuSkeleton'
import { DocumentVersionSwitchDiffMobile } from '../DocumentVersionSwitchDiff'

interface DocumentVersionsMenuMobileProps {
  data: DocumentData | undefined
  isLoading: boolean
  isFetching: boolean
  firstVersionData: DocumentData | undefined
  secondVersionData: DocumentData | undefined

  selectedVersionIdMobile: string
  setSelectedVersionIdMobile: Dispatch<SetStateAction<string>>
}

export const DocumentVersionsMenuMobile = ({
  data,
  isLoading,
  isFetching,
  selectedVersionIdMobile,
  setSelectedVersionIdMobile,
}: DocumentVersionsMenuMobileProps) => {
  const {
    versions,
    isOpenVersionSelect,
    closeVersionSelect,
    handleCloseVersion,

    firstVersionId,
    firstVersionSelect,
    onFirstValueChange,

    secondVersionId,
    secondVersionSelect,
    onSecondValueChange,

    handleChangeSelectedVersionId,
  } = useDocumentVersionSelect(
    data,
    selectedVersionIdMobile,
    setSelectedVersionIdMobile
  )

  if (isLoading || isFetching) {
    return <DocumentVersionsMenuSkeleton />
  }

  if (!data) {
    return null
  }

  return (
    <div>
      <IconButton
        className="m-2 h-7 w-7"
        onClick={handleCloseVersion}
      >
        <IconChevronLeft width={'56px'} height={'56px'} />
      </IconButton>

      <div className="p-2">
        <div
          className="flex w-full flex-col items-center justify-between gap-[6px] rounded-xl
            border-[1px] border-base-100 p-2 shadow-shadow-modal-light"
        >
          <div className="w-full">
            <RadioGroup
              value={selectedVersionIdMobile}
              onValueChange={handleChangeSelectedVersionId}
              className="flex gap-6"
            >
              {Object.entries({
                'Редакция 1': firstVersionId,
                'Редакция 2': secondVersionId,
              }).map(([value, label]) => (
                <label
                  key={value}
                  className="flex h-6 w-1/2 items-center justify-start gap-3"
                >
                  <RadioGroupItem
                    value={String(label)}
                    className="h-5 w-5"
                  />
                  <Typography
                    variant="body"
                    className="text-xs font-medium"
                  >
                    {value}
                  </Typography>
                </label>
              ))}
            </RadioGroup>
          </div>
          <div className="flex w-full items-center gap-4">
            <div className="w-1/2">
              <DocumentVersionSelectMobile
                versions={firstVersionSelect}
                currentVersion={versions[0]}
                onSelectChange={onFirstValueChange}
              />
            </div>
            <div className="w-1/2">
              <DocumentVersionSelectMobile
                versions={secondVersionSelect}
                currentVersion={versions[1]}
                onSelectChange={onSecondValueChange}
              />
            </div>
          </div>
          <div className="flex w-full items-center gap-4">
            <DocumentVersionSwitchDiffMobile
              current={1}
              total={1000}
              type="added"
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
      <DialogDocumentVersionSelect
        isOpen={isOpenVersionSelect}
        toggleClose={closeVersionSelect}
        versions={data.versions}
      />
    </div>
  )
}
