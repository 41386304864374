import { useGetDocumentsQuery } from '@/shared/api/documents'
import { useIntersectionObserver } from '@/shared/hooks/useIntersactionObserver'
import { useSetDocumentsCitations } from '@/shared/hooks/useSetDocumentsCitations'
import { omitKeys } from '@/shared/utils/object'
import { scrollContainerToTop } from '@/shared/utils/scrollContainerToTop'
import { useCallback, useEffect, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { buildDocumentQueryParams } from '../utils'

export const useDocuments = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryOptions = buildDocumentQueryParams(searchParams)
  const currentPage = Number(searchParams.get('page')) || 1

  const prevParamsRef = useRef<Record<string, string>>({})

  useEffect(() => {
    const currentParamsObj = Object.fromEntries(
      searchParams.entries()
    )

    const currentFiltered = omitKeys(currentParamsObj, [
      'sort_mode',
      'page',
    ])
    const prevFiltered = omitKeys(prevParamsRef.current, [
      'sort_mode',
      'page',
    ])

    // If anything changed (besides sort_mode/page) & page != 1 => reset page to 1
    if (!shallowEqual(currentFiltered, prevFiltered)) {
      if (currentParamsObj.page !== '1') {
        setSearchParams({
          ...currentParamsObj,
          page: '1',
        })
      }
    }

    // Update the ref to the latest full set of params
    prevParamsRef.current = currentParamsObj
  }, [searchParams, setSearchParams])

  const { data, error, isFetching, isLoading } = useGetDocumentsQuery(
    {
      ...queryOptions,
      page: currentPage,
    }
  )

  // setting higlighted paragraphs if there are
  useSetDocumentsCitations(data?.documents)

  const [isLastItemVisible, setIsLastItemVisible] =
    useState<boolean>(false)

  const handleIntersect = useCallback(
    (isVisible: boolean) => {
      setIsLastItemVisible((prev) =>
        prev !== isVisible ? isVisible : prev
      )
    },
    [setIsLastItemVisible]
  )

  // useIntersectionObserver returns a ref setter for the “last document” element
  const lastDocumentRef = useIntersectionObserver({
    onIntersect: handleIntersect,
  })

  useEffect(() => {
    scrollContainerToTop('documents_container')
  }, [currentPage, data])

  return {
    data,
    error,
    isLoading,
    isFetching,
    currentPage,
    lastDocumentRef,
    isLastItemVisible,
  }
}
