import { ECategoryType } from '@/entities/filter'
import SearchIcon from '@/shared/assets/icons/icon_search_dark.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { cn } from '@/shared/utils/common'
import React, { Dispatch, SetStateAction } from 'react'

import { useCategoriesFiltersSearch } from '../../models/useCategoriesFiltersSearch'
import { Filter } from '../../types'

interface CustomFilterInputProps {
  categoryType: ECategoryType
  setTotalCount: Dispatch<SetStateAction<number>>
  setFilterItems: Dispatch<SetStateAction<Filter[]>>
  setIsFiltered: Dispatch<React.SetStateAction<boolean>>
}

export const CustomFilterInput: React.FC<CustomFilterInputProps> = ({
  categoryType,
  setFilterItems,
  setTotalCount,
  setIsFiltered,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const getPlaceholder = () => {
    switch (categoryType) {
      case 'types':
        return 'Найти тип документа...'
      case ECategoryType.APPROVING_ORGANIZATIONS:
        return 'Найти организацию...'
      case ECategoryType.DEVELOPING_ORGANIZATIONS:
        return 'Найти организацию...'
      case 'groups':
        return 'Найти раздел...'
      default:
        return ''
    }
  }

  const { searchText, setSearchText } = useCategoriesFiltersSearch({
    categoryType: categoryType,
    setFilterItems: setFilterItems,
    setTotalCount: setTotalCount,
    setIsFiltered: setIsFiltered,
  })

  return (
    <div
      className={cn(
        `flex max-h-9 flex-1 items-center gap-2 rounded-[8px] border-[1px]
        border-stroke-light-gray-2 px-3 py-0`,
        isMobile &&
          'flex-row-reverse rounded-[100px] bg-bg-light-gray-2 px-4 py-[10px]'
      )}
    >
      <SearchIcon className="h-4 w-4 [&_path]:stroke-[#67707E]" />
      <input
        type="text"
        placeholder={getPlaceholder()}
        value={searchText}
        className={cn(
          `flex-1 border-none bg-transparent py-2 pr-2 font-normal leading-[20px]
          text-text-light-black-3`,
          'outline-none placeholder:text-[14px] placeholder:text-[#67707E]',
          isMobile && 'py-1'
        )}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  )
}
