import { useChatAiFile } from '@/entities/chat/models/useChatAiFile'
import { DocumentItemStatus } from '@/entities/document/ui/DocumentItemStatus'
import '@/entities/panel/ui/RawHtmlMainRenderer/document-style.css'
import { MenubarAddDocumentToFolder } from '@/features/document/ui/MenubarAddDocumentToFolder'
import { MenubarOpenDocumentInNewTab } from '@/features/document/ui/MenubarOpenDocumentInNewTab'
import { MenubarToggleFavoriteDocument } from '@/features/document/ui/MenubarToggleFavoriteDocument'
import IconFullscreen from '@/shared/assets/icons/icon_fullscreen.svg?react'
import IconDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import { IconButton } from '@/shared/ui'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { Arrow } from '@radix-ui/react-menubar'

import { IChatAiFile } from '../../../entities/chat/types'

type ChatAiFileProps = {
  file: IChatAiFile
}

export const ChatAiFile = ({ file }: ChatAiFileProps) => {
  const { handleOpenDocument } = useChatAiFile(file)

  return (
    <Menubar
      key={file.id}
      className="flex items-center justify-stretch self-stretch"
    >
      <MenubarMenu>
        <div
          className="flex w-full flex-col items-stretch justify-start gap-1 rounded-[16px]
            border border-stroke-light-gray-2 bg-white p-4 shadow-shadow7"
        >
          <div className="flex items-center justify-between">
            <DocumentItemStatus
              status={file.status.code}
              label={file.status.name}
            />
            <MenubarTrigger
              asChild
              className="data-[state=open]:bg-base-100"
            >
              <IconButton
                className="h-6 w-6 -translate-y-[8px] translate-x-[8px] cursor-pointer rounded-lg
                  hover:scale-100 hover:bg-base-200"
              >
                <IconDots />
              </IconButton>
            </MenubarTrigger>
          </div>
          <div className="flex items-start justify-between">
            <Typography
              variant={'body'}
              className="mt-1 break-words text-start font-medium leading-[24px] text-black"
            >
              {file.name}
            </Typography>
          </div>

          {file.requisites ? (
            <ul
              className="list-disc flex-col items-stretch justify-start gap-1 pl-4
                text-base-700 marker:text-base-700"
            >
              <li>
                <div className="inline-flex w-full items-start justify-start">
                  <Typography
                    variant={'label'}
                    className="text-start font-medium"
                  >
                    {file.requisites}
                  </Typography>
                </div>
              </li>
            </ul>
          ) : null}
        </div>
        <MenubarContent
          collisionPadding={{ right: -20, top: 10, bottom: 15 }}
          className="pointer-events-auto visible z-50 flex min-w-[184px] max-w-[184px]
            flex-col items-stretch rounded-[16px] bg-bg-light-white-2 p-2
            shadow-shadow2"
          align="end"
          alignOffset={-16}
          sideOffset={-3}
        >
          <Arrow fill="#fcfcfc" className="h-2 w-4" />

          <MenubarItem
            className="flex items-center justify-start gap-2 rounded-lg p-2
              hover:cursor-pointer hover:bg-base-100"
            onClick={handleOpenDocument}
            inset={true}
          >
            <IconFullscreen className="h-5 w-5" />
            <Typography
              variant="label"
              className="whitespace-nowrap font-normal text-black"
            >
              Открыть здесь
            </Typography>
          </MenubarItem>
          <MenubarOpenDocumentInNewTab fileId={file.id} />
          <MenubarToggleFavoriteDocument file={file} />
          <MenubarAddDocumentToFolder fileId={file.id} />
          {/* {chats.documents && chats.documents.length >= 1 ? (
            <MenubarItem
              className="flex items-center justify-start gap-2 rounded-lg p-2
                hover:cursor-pointer hover:bg-base-100"
              onClick={handleOpenDocument}
              inset={true}
            >
              <IconRowVertical className="h-5 w-5" />
              <Typography
                
                className="whitespace-nowrap font-normal text-black"
              >
                Сравнить
              </Typography>
            </MenubarItem>
          ) : null} */}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
