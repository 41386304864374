import { IDocument } from '@/entities/document'
import { useEffect } from 'react'

import { useAppDispatch } from '../store'
import { setCitationsMap } from '../store/slices/documents/documentsSlice'

export function useSetDocumentsCitations(documents?: IDocument[]) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!documents) return
    dispatch(setCitationsMap(documents))
  }, [dispatch, documents])
}
