import { DialogDocumentVersionSelect } from '@/entities/document'
import { DocumentData } from '@/entities/document/types'
import IconBurger from '@/shared/assets/icons/icon_burger.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import {
  Menubar,
  MenubarContent,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { cn } from '@/shared/utils/common'

import { useDocumentVersionSelect } from '../../hooks/useDocumentVersionSelect'
import { DocumentVersionMenu } from '../DocumentVersionMenu'
import { DocumentVersionSelect } from '../DocumentVersionSelect'
import { DocumentVersionsMenuSkeleton } from '../DocumentVersionsMenuSkeleton'

interface DocumentVersionsMenuProps {
  data: DocumentData | undefined
  isLoading: boolean
  isFetching: boolean
  firstVersionData: DocumentData | undefined
  secondVersionData: DocumentData | undefined
}

export const DocumentVersionsMenu = ({
  data,
  isLoading,
  isFetching,
  firstVersionData,
  secondVersionData,
}: DocumentVersionsMenuProps) => {
  const {
    handleCloseVersion,
    openFirstMenu,
    openVersionSelectFirst,
    firstVersionId,
    firstVersionSelect,
    versions,
    onFirstValueChange,
    secondVersionSelect,
    onSecondValueChange,
    openSecondMenu,
    openVersionSelectSecond,
    isOpenVersionSelect,
    closeVersionSelect,
    secondVersionId,
  } = useDocumentVersionSelect(data)
  if (isLoading || isFetching) {
    return <DocumentVersionsMenuSkeleton />
  }

  if (!data) {
    return null
  }

  return (
    <div
      className="relative rounded-[12px] border border-base-100 bg-white
        shadow-shadow-modal-light"
    >
      <div
        className="flex h-[36px] items-center justify-between border-b-[1px]
          border-base-100 px-4"
      >
        <Typography
          variant={'label'}
          className="truncate text-base-700"
        >
          {data && data.name}
        </Typography>
        <IconButton className="h-6 w-6" onClick={handleCloseVersion}>
          <IconClose width={'20px'} height={'20px'} />
        </IconButton>
      </div>
      <div className="flex h-[40px] items-center justify-between px-4">
        <div className="flex items-center gap-4">
          <Menubar>
            <MenubarMenu>
              <MenubarTrigger
                onClick={openFirstMenu}
                className="data-[state=open]:bg-base-100"
              >
                <IconButton
                  className={cn('h-6 w-6 hover:bg-base-100')}
                  onClick={openFirstMenu}
                >
                  <IconBurger />
                </IconButton>
              </MenubarTrigger>
              <MenubarContent
                collisionPadding={{ right: -20, top: 10, bottom: 15 }}
                className="pointer-events-auto visible z-50 w-[440px] gap-3 rounded-[12px]"
                align={'start'}
                alignOffset={-10}
                sideOffset={-5}
              >
                <DocumentVersionMenu
                  dataLanguages={firstVersionData?.locale.available}
                  toggleMenu={openFirstMenu}
                  openVersionSelect={openVersionSelectFirst}
                  documentId={firstVersionId}
                  isSecond={false}
                />
              </MenubarContent>
            </MenubarMenu>
          </Menubar>

          <DocumentVersionSelect
            versions={firstVersionSelect}
            currentVersion={versions[0]}
            onSelectChange={onFirstValueChange}
          />
        </div>

        <div className="flex items-center gap-4">
          <DocumentVersionSelect
            versions={secondVersionSelect}
            currentVersion={versions[1]}
            onSelectChange={onSecondValueChange}
          />
          <Menubar>
            <MenubarMenu>
              <MenubarTrigger
                onClick={openSecondMenu}
                className="data-[state=open]:bg-base-100"
              >
                <IconButton
                  className={cn('h-6 w-6 hover:bg-base-100')}
                  onClick={openSecondMenu}
                >
                  <IconBurger />
                </IconButton>
              </MenubarTrigger>
              <MenubarContent
                collisionPadding={{ right: -20, top: 10, bottom: 15 }}
                className="pointer-events-auto visible z-50 w-[440px] gap-3 rounded-[12px]"
                align={'end'}
                alignOffset={-10}
                sideOffset={-5}
              >
                <DocumentVersionMenu
                  isSecond={true}
                  dataLanguages={secondVersionData?.locale.available}
                  toggleMenu={openSecondMenu}
                  openVersionSelect={openVersionSelectSecond}
                  documentId={secondVersionId}
                />
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
        </div>
      </div>

      <DialogDocumentVersionSelect
        isOpen={isOpenVersionSelect}
        toggleClose={closeVersionSelect}
        versions={data.versions}
      />
    </div>
  )
}
