import { useRawHtmlMainRenderer } from '@/entities/document/models/useRawHtmlMainRenderer'

import './document-style.css'

interface RawHtmlMainRendererProps {
  rawHtml: string
  index: number
  isVersions?: boolean
}

export const RawHtmlMainRenderer = ({
  rawHtml,
  index,
  isVersions,
}: RawHtmlMainRendererProps) => {
  const { rawRef } = useRawHtmlMainRenderer({
    index,
    isVersions,
  })

  return (
    <div
      ref={rawRef}
      dangerouslySetInnerHTML={{ __html: rawHtml }}
      className="documentContent documentPreview publicate"
    />
  )
}
