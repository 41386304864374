import { useDocumentContents } from '@/features/document/hooks/useDocumentContent'
import { useTypedSelector } from '@/shared/store'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

interface useRawHtmlMainRendererProps {
  index: number
  isVersions?: boolean
}

export const useRawHtmlMainRenderer = ({
  index,
  isVersions,
}: useRawHtmlMainRendererProps) => {
  const rawRef = useRef<HTMLDivElement>(null)
  const citiationParagraps = useTypedSelector(
    (state) => state.documents.citationsMap
  )
  const { id: documentId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const documentCitiationParagraps =
    citiationParagraps[Number(documentId)]
  citiationParagraps[Number(documentId)]
  const anchorId = searchParams.get('anchorId')
  const highlightParagraphs = searchParams.get('highlight_paragraphs')
  const updatedCitiationParagraps = highlightParagraphs
    ? highlightParagraphs.split(',')
    : documentCitiationParagraps
  const [tableWidth, setTableWidth] = useState(0)
  const {
    isShownChunks,
    isShowFirstVersionChunks,
    isShowSecondVersionChunks,
  } = useDocumentContents()

  const chunksConfig = {
    0: isShownChunks,
    1: isShowFirstVersionChunks,
    2: isShowSecondVersionChunks,
  }

  const isShowChunks =
    chunksConfig[index as keyof typeof chunksConfig]

  useEffect(() => {
    if (isVersions) {
      return
    }
    if (!rawRef.current || !updatedCitiationParagraps) {
      return
    }
    updatedCitiationParagraps.forEach((docIndex) => {
      const targetDoc = rawRef.current!.querySelector(
        `div[data-docindex="${docIndex}"]`
      )
      if (targetDoc instanceof HTMLElement) {
        targetDoc.classList.add('highlight-div')
      }
    })
  }, [rawRef.current, updatedCitiationParagraps])

  useEffect(() => {
    if (rawRef.current) {
      const isThereATable =
        rawRef.current.querySelectorAll('.table-wrapper')
      if (isThereATable.length) {
        const { width } = rawRef.current.getBoundingClientRect()
        setTableWidth(Number(width.toFixed(0)))
        isThereATable.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.width = `${tableWidth - 30}px`
          }
        })
      }
    }
  }, [rawRef.current])

  useEffect(() => {
    if (rawRef.current) {
      const allChunks =
        rawRef.current.querySelectorAll('.note.cls-text ')
      allChunks.forEach((chunk) => {
        if (chunk instanceof HTMLElement) {
          chunk.style.transform = isShowChunks
            ? 'scaleY(0)'
            : 'scaleY(1)'
          chunk.style.height = isShowChunks ? '0' : 'auto'
        }
      })
    }
  }, [isShowChunks])

  useEffect(() => {
    if (!anchorId || !rawRef.current) return

    // Find the div that matches the docIndex
    const targetDiv = rawRef.current.querySelector(
      `div[data-docindex="${anchorId}"]`
    ) as HTMLElement | null

    if (targetDiv) {
      // Smooth scroll to that div
      targetDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [anchorId])

  // useEffect(() => {
  //   if (documentCitiationParagraps) {
  //     searchParams.set(
  //       'highlight_paragraphs',
  //       documentCitiationParagraps.join(',')
  //     )
  //     setSearchParams(searchParams)
  //   }
  // }, [documentCitiationParagraps])

  return {
    rawRef,
  }
}
