import {
  CourtBaseMenuItem,
  ICourtItem,
} from '@/entities/courtBase/types'
import { DocumentBaseMenuItem } from '@/entities/document/types'
import IconArrowThinkUp from '@/shared/assets/icons/icon_arrow_thick_up.svg?react'
import IconDownloadDark from '@/shared/assets/icons/icon_download_dark.svg?react'
import IconFolderPlus from '@/shared/assets/icons/icon_folder_plus.svg?react'
import IconInfoDark from '@/shared/assets/icons/icon_info_dark.svg?react'
import IconMarkBlue from '@/shared/assets/icons/icon_mark_blue.svg?react'
import IconMarkDark from '@/shared/assets/icons/icon_mark_dark.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

interface CourtBaseDocumentActionsMenuProps {
  children?: ReactNode
  courtBaseItem: ICourtItem
}

export const CourtBaseDocumentActionsMenu = ({
  children,
  courtBaseItem,
}: CourtBaseDocumentActionsMenuProps) => {
  const navigate = useNavigate()
  const isFavorite = courtBaseItem.is_favorite
    ? courtBaseItem.is_favorite
    : true

  const courtBaseMenuItems: CourtBaseMenuItem[] = [
    {
      title: 'О деле',
      icon: <IconInfoDark className="h-5 w-5" />,
    },
    {
      title: isFavorite ? 'В избранном' : 'В избранное',
      icon: isFavorite ? (
        <IconMarkBlue className={'h-5 w-5'} />
      ) : (
        <IconMarkDark className={'h-5 w-5'} />
      ),
      className: 'hover:bg-additional-light-blue',
    },
    {
      title: 'Скачать',
      icon: <IconDownloadDark className="h-5 w-5" />,
    },
    {
      title: 'В папку',
      icon: <IconFolderPlus className="h-5 w-5" />,
    },
  ]
  return (
    <>
      <Menubar className="flex items-start justify-stretch self-stretch border-none">
        <MenubarMenu>
          <MenubarTrigger
            className={cn(
              `absolute right-3 flex h-6 w-6 items-center justify-start rounded-lg
              bg-bg-light-white-1 hover:cursor-pointer hover:bg-bg-light-gray-2
              data-[state=open]:bg-base-100`
            )}
          >
            {children ? children : <IconMoreDotsDark />}
          </MenubarTrigger>

          <MenubarContent
            className="relative flex flex-col items-stretch rounded-[20px] bg-white p-2"
            align="end"
            // TODO: collision Boundary нужно будет обдумать
            style={{
              boxShadow:
                '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
            }}
            alignOffset={-12}
            sideOffset={4}
          >
            <IconArrowThinkUp className="absolute -top-[14px] right-[15px] h-5 w-5" />

            {/* Menu Items */}
            {courtBaseMenuItems.map((item, index) => {
              return (
                <MenubarItem
                  onClick={item.onClick}
                  inset={true}
                  className="p-0"
                  key={index}
                >
                  <div
                    className={cn(
                      `flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
                      hover:bg-slate-100`,
                      index + 1 === courtBaseMenuItems.length
                        ? 'rounded-t-none border-t-[1px] border-base-100'
                        : '',
                      item?.className
                    )}
                  >
                    {item.icon}
                    <Typography
                      className="text-nowrap font-normal"
                      variant="label"
                    >
                      {item.title}
                    </Typography>
                  </div>
                </MenubarItem>
              )
            })}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </>
  )
}
